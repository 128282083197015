import widgets from "styles/widgets";

export default function Popup(props: {
    title: string,
    description: string,
    confirmText: string,
    confirmAction: () => void,
    cancelText?: string,
    cancelAction?: () => void,
})
{
    // Component:
    const widgetClasses = widgets();
    return (
        <div className={`${widgetClasses.popup} ${widgetClasses.form}`}>
            <h1>{props.title}</h1>
            <p>{props.description}</p>
            <div className={widgetClasses.row}>
                <button className={widgetClasses.confirm} onClick={props.confirmAction}>
                    {props.confirmText}
                </button>
                {props.cancelText && props.cancelAction && (
                    <button onClick={props.cancelAction}>
                        {props.cancelText}
                    </button>
                )}
            </div>
        </div>
    );
}