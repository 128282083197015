import { socketsService, loginService, digimonService, itemService, zoneService } from "services";

import { LoginDto } from "dtos/login.dto";
import { ZoneDto } from "dtos/zone.dto";
import { UserDto } from "dtos/user.dto";
import { DigimonDto } from "dtos/digimon.dto";
import { ItemDto } from "dtos/item.dto";

import { UserActor, UserState } from "actors/user.actor";
import { DigimonActor, DigimonState } from "actors/digimon.actor";
import { ItemActor, ItemState } from "actors/item.actor";
import { Vector3, Vector3Dto } from "dtos/vector3.dto";

export interface GameState
{
    ticks: number;
    seconds: number;

    zone?: ZoneDto;
    homeZone: boolean;
    dayProgress: number;
    phaseProgress: number;
    phase: number;
    zoneScale: Vector3Dto;

    mainUser?: UserState;
    mainDigimon?: DigimonState;
    users: Record<string, UserState>;
    digimon: Record<string, DigimonState>;
    items: Record<string, ItemState>;
    itemTypeCounts: Record<string, number>;
    ownedItemTypeCounts: Record<string, number>;
}

export class GameActor
{
    public tickListeners: (() => void)[] = [];
    public interval: NodeJS.Timer;

    public ticks: number = 30 * 60 * 10; // The number of game ticks that have passed (synced to region on zone warping).
    public seconds: number = 0; // The number of game seconds that have passed (synced to region on zone warping).
    public onSecond: boolean = false; // True if the current tick is at the start of a new second.

    public zone?: ZoneDto; // The dto of the zone the user is currently in.
    public dayProgress: number = 0; // The current progress of the day.
    public phaseProgress: number = 0; // The progress to the next day phase.
    public phase: number = 0; // The current phase of the day. 0 = Dawn, 1 = Day, 2 = Dusk, 3 = Night
    public zoneScale: Vector3Dto = Vector3.ONE(); // The scale of the zone used to scale actors.

    public userActor?: UserActor; // An instance of the main user actor.
    public digimonActor?: DigimonActor; // An instance of the main digimon actor.
    public userActors: Record<string, UserActor>; // A map of user actors in game by id.
    public digimonActors: Record<string, DigimonActor>; // A map of digimon actors in game by id.
    public itemActors: Record<string, ItemActor>; // A map of item actors in game by id.

    public readonly fps: number = 30; // Frames per second.

    /**
     * Constructor (Starts the main update interval of the game.)
     */
    public constructor()
    {
        this.interval = setInterval(() => this.tick(), 1000 / this.fps);
        this.userActors = {};
        this.digimonActors = {};
        this.itemActors = {};

        socketsService.addListener(this.onSocketsEvent.bind(this));
        loginService.addListener(this.onLoginEvent.bind(this));
        zoneService.addListener(this.onZoneEvent.bind(this));
        digimonService.addListener(this.onDigimonEvent.bind(this));
        itemService.addListener(this.onItemEvent.bind(this));
    }

    /**
     * Returns the current game state.
     */
    public getState(): GameState
    {
        return {
            ticks: this.ticks,
            seconds: this.seconds,

            dayProgress: this.dayProgress,
            phaseProgress: this.phaseProgress,
            phase: this.phase,
            zoneScale: this.zoneScale,

            zone: this.zone,
            homeZone: this.zone?.id === this.userActor?.dto.homeZoneId,
            mainUser: this.userActor?.getState(),
            mainDigimon: this.digimonActor?.getState(),

            users: Object.values(this.userActors).reduce((map, actor) => {
                map[actor.getState().dto.id] = actor.getState();
                return map;
            }, {} as Record<string, any>),
            digimon: Object.values(this.digimonActors).reduce((map, actor) => {
                map[actor.getState().dto.id] = actor.getState();
                return map;
            }, {} as Record<string, any>),
            items: Object.values(this.itemActors).reduce((map, actor) => {
                map[actor.getState().dto.id] = actor.getState();
                return map;
            }, {} as Record<string, any>),

            itemTypeCounts: Object.values(this.itemActors)
                .reduce((map, itemActor) => {
                    if (map[itemActor.dto.type]) {
                        map[itemActor.dto.type]++;
                        return map;
                    }
                    map[itemActor.dto.type] = 1;
                    return map;
                }, {} as Record<string, number>),
            ownedItemTypeCounts: Object.values(this.itemActors)
                .filter(itemActor => itemActor.dto.userId === this.userActor?.dto.id)
                .reduce((map, itemActor) => {
                    if (map[itemActor.dto.type]) {
                        map[itemActor.dto.type]++;
                        return map;
                    }
                    map[itemActor.dto.type] = 1;
                    return map;
                }, {} as Record<string, number>),
        };
    }

    /**
     * Adds a tick event listener.
     * @param listener The event listener callback function to call.
     * @return Returns a callback to remove the event listener.
     */
    public addTickListener(listener: () => void): () => void
    {
        this.tickListeners.push(listener);
        return () => this.tickListeners = this.tickListeners.filter(existingListener => existingListener !== listener);
    }

    /**
     * The main tick function of the game.
     */
    public tick(): void
    {
        this.ticks++;
        const secondsPrev: number = this.seconds;
        const seconds: number = this.ticks / this.fps;
        this.seconds = Math.floor(seconds);
        this.onSecond = secondsPrev !== this.seconds;

        // Region Time:
        const hours: number = seconds / 60 / 60;
        this.dayProgress = hours % 1;
        const phase: number = (this.dayProgress * 4) % 4;
        this.phaseProgress = phase % 1;
        this.phase = Math.floor(phase);

        // Tick Actors:
        Object.values(this.userActors).forEach(actor => actor.tick());
        Object.values(this.digimonActors).forEach(actor => actor.tick());
        Object.values(this.itemActors).forEach(actor => actor.tick());

        // Update Tick Listeners:
        this.tickListeners.forEach(listener => listener());
    }

    /**
     * Called when sockets events are received.
     * @param event The name of the event.
     */
    private onSocketsEvent(event: string): void
    {
        // Remove user actor on disconnect:
        if (event === 'disconnect' || event === 'connect_error') {
            this.userActor = undefined;
            this.digimonActor = undefined;
            this.userActors = {};
            this.digimonActors = {};
            this.itemActors = {};
        }
    }

    /**
     * Called when login events are received. Creates or updates various actors.
     * @param event The name of the event.
     * @param loginDto The data to update actors with.
     */
    private onLoginEvent(event: string, loginDto: LoginDto): void
    {
        // Set Username:
        console.log(`[Game] Logged in with username: ${loginDto.user.username}`);

        // Zone Event:
        this.onZoneEvent("zone", loginDto.zone);

        // User Event:
        this.onUserEvent("user", loginDto.user, true);
    }

    /**
     * Called when zone events are received.
     * @param event The name of the event.
     * @param zoneDto The data to update with.
     */
    private onZoneEvent(event: string, zoneDto: ZoneDto): void
    {
        this.zone = zoneDto;

        this.userActors = {};
        this.digimonActors = {};
        this.itemActors = {};

        this.ticks = zoneDto.region.seconds * this.fps;
        console.log(`[Game] Warped to region: ${this.zone.region.name} zone: ${this.zone.id}`);

        // Zone Scale:
        this.zoneScale = {
            x: zoneDto.size.x / 100,
            y: 1,
            z: zoneDto.size.z / 100,
        };

        // Nested Events:
        zoneDto.users.forEach(dto => this.onUserEvent("user", dto, false));
        zoneDto.digimon.forEach(dto => this.onDigimonEvent("digimon", dto));
        zoneDto.items.forEach(dto => this.onItemEvent("item", dto));
    }

    /**
     * Called when user events are received. Creates or updates the user actor.
     * @param event The name of the event.
     * @param userDto The data to update the user actor with.
     * @param mainUser If true, this is the main user, if false the user is determined by the id.
     */
    private onUserEvent(event: string, userDto: UserDto, mainUser: boolean): void
    {
        switch (event) {
            case "user":
                let userActor: UserActor | undefined = this.userActors[userDto.id];

                // Create New Actor:
                if (!userActor) {
                    userActor = new UserActor(this, userDto);
                    this.userActors[userDto.id] = userActor;
                }

                // Main User Actor:
                if (mainUser) {
                    this.userActor = userActor;
                }

                // Update User:
                userActor.update(userDto);

                // Nested Digimon Event:
                if (userDto.digimon) {
                    this.onDigimonEvent("digimon", userDto.digimon);
                }
            break;
        }
    }

    /**
     * Called when digimon events are received. Creates or updates the digimon actor.
     * @param event The name of the event.
     * @param digimonDto The data to update the digimon actor with.
     */
    private onDigimonEvent(event: string, digimonDto: DigimonDto): void
    {
        switch (event) {
            case "digimon":
                let digimonActor: DigimonActor | undefined = this.digimonActors[digimonDto.id];

                // Create New Actor:
                if (!digimonActor) {
                    digimonActor = new DigimonActor(this, digimonDto);
                    this.digimonActors[digimonDto.id] = digimonActor;
                }

                // Main Digimon:
                if (digimonDto.owner === this.userActor?.dto.username) {
                    this.digimonActor = digimonActor;
                }

                // Update Digimon:
                digimonActor.update(digimonDto);
                break;

            case "digimon.remove":
            case "digimon.destroy":
                delete this.digimonActors[digimonDto.id];

                // Main Digimon:
                if (digimonDto.owner === this.userActor?.dto.username) {
                    if (this.digimonActor?.dto.id === digimonDto.id) {
                        this.digimonActor = undefined;
                    }
                }
                break;
        }
    }

    /**
     * Called when item events are received. Creates or updates the item actor.
     * @param event The name of the event.
     * @param itemDto The data to update the item actor with.
     */
    private onItemEvent(event: string, itemDto: ItemDto): void
    {
        if (event === "item.destroy") {
            delete this.itemActors[itemDto.id];
            return;
        }

        // Create or update item actor:
        if (!this.itemActors[itemDto.id]) {
            this.itemActors[itemDto.id] = new ItemActor(this, itemDto);
            // console.log(`[Game] Created item: ${this.itemActors[itemDto.id].dto.display}`);
        } else {
            this.itemActors[itemDto.id].update(itemDto);
        }
    }

    /**
     * Calculates the viewport width.
     * @param percent The percentage to get.
     * @returns The viewport width in pixels.
     */
    public vw(percent: number): number
    {
        var w = Math.max(document.documentElement.clientWidth, window.innerWidth || 0);
        return (percent * w) / 100;
    }

    /**
     * Calculates the viewport height.
     * @param percent The percentage to get.
     * @returns The viewport height in pixels.
     */
    public vh(percent: number): number
    {
        var h = Math.max(document.documentElement.clientHeight, window.innerHeight || 0);
        return (percent * h) / 100;
    }
}