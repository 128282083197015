import { Socket } from "socket.io-client"

import { AbstractService } from "services/abstract.service";
import { ZoneDto } from "dtos/zone.dto";

export class ZoneService extends AbstractService<ZoneDto>
{
    /**
     * Constructor
     * @param socket The connection socket.
     */
    public constructor(socket: Socket)
    {
        super(socket);

        // Zone:
        this.socket.on('zone', (data: ZoneDto) => {
            // console.log(`[Zone Service] Zone ${data.name} updated.`);
            this.listeners.forEach(listener => listener('zone', data));
        });
    }
}