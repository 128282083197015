import { useEffect, useState } from "react";
import { createUseStyles } from "react-jss";

import variables from "styles/variables";
import Bar from "components/widgets/bar";
import { ItemState } from "actors/item.actor";
import { itemService } from "services";

const styles = createUseStyles<any, {
    mouseX: number,
    mouseY: number,
    flipX: boolean,
    flipY: boolean,
    color: string,
    lines: number,
}>({
    container: {
        position: "fixed",
        left: (props) => !props.flipX ? props.mouseX + 16 : props.mouseX - 32 - 300,
        // right: (props) => props.flipX ? props.mouseX - 16 : undefined,
        top: (props) => props.mouseY - (props.flipY ? (24 * props.lines) + 20 : 0),
        zIndex: 1024,
        width: 300,
        padding: 8,
        borderStyle: "solid",
        borderColor: (props) => props.color,
        backgroundColor: (props) => props.color + "CC",
        backgroundSize: "contain",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
        color: variables.colors.background,
        fontSize: "0.9em",
        textShadow: "-1px 0 black, 0 1px black, 1px 0 black, 0 -1px black",
        "& h3": {
            fontSize: "1.2em",
            margin: 0,
        },
        "& p": {
            margin: 0,
        },
    },
    bar: {
        width: 300 - 8,
        margin: "2px 0",
        padding: 1,
    },
});

export default function ItemTooltip(props: {
    item: ItemState,
})
{
    // States:
    const [mouseState, setMouseState] = useState({
        mouseX: -1000,
        mouseY: -1000,
        flipX: false,
        flipY: false,
    });
    let lines: number = 1;

    // Mouse:
    useEffect(() => {
        const handleMouseMove = (event: MouseEvent) => {
            setMouseState({
                mouseX: event.clientX,
                mouseY: event.clientY,
                flipX: event.clientX > (window.innerWidth * 0.5),
                flipY: event.clientY > (720 * 0.5),
            });
        };
        window.addEventListener("mousemove", handleMouseMove);
        return () => {
            window.removeEventListener("mousemove", handleMouseMove);
        };
    }, []);

    // Info:
    const description: string = itemService.getDescription(props.item.dto.name, props.item.dto.type);
    let metadata: JSX.Element | JSX.Element[] = (<></>);

    // Food Metadata:
    if (props.item.dto.type === "food") {
        metadata = itemService.getHighs(props.item.dto).map(entry => (
            <p key={entry.key}>High in {entry.name}</p>
        ));
    }

    // Poop Metadata Values:
    if (props.item.dto.type === "poop") {
        lines += itemService.getMetadata(props.item.dto).length;
    }

    // Classes:
    const classes = styles({
        ...mouseState,
        color: itemService.getColor(props.item.dto.type) ?? variables.colors.neutral,
        lines: lines + 2 + Math.floor(description.length / 32),
    });

    // Poop Metadata Components:
    if (props.item.dto.type === "poop") {
        metadata = itemService.getMetadata(props.item.dto).map(entry => {
            return (<Bar
                key={entry.key}
                name={entry.name}
                current={entry.value}
                max={100}
                showPercentage={true}
                color={variables.colors.positive + "AA"}
                colorMed={variables.colors.average + "AA"}
                colorLow={variables.colors.negative + "AA"}
                className={classes.bar}
            />);
        });
    }

    // Component:
    return (
        <div className={`${classes.container}`}>
            <h3>{props.item.nameDisplay}</h3>
            <p><b>{props.item.typeDisplay}</b> {props.item.dto.dateCreated}</p>
            <p><b>Owner:</b> {props.item.ownerName}</p>
            <p>{description}</p>
            {metadata}
        </div>
    );
}