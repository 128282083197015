import { createUseStyles } from "react-jss";

import variables from "styles/variables";

const styles = createUseStyles<any, any>({
    barBackground: {
        position: "relative",
        flexGrow: 1,
        padding: 4,
        margin: 0,
        borderWidth: 2,
        borderStyle: "solid",
        borderColor: variables.colors.background,
        backgroundColor: variables.colors.foreground + "AA",
        textAlign: "center",
        "& > span": {
            position: "relative",
        },
    },
    barForeground: {
        position: "absolute",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
    },
});

export default function Bar(props: {
    current: number,
    max: number,
    name: string,
    unit?: string,
    showValue?: boolean,
    showPercentage?: boolean,
    color?: string,
    colorMed?: string,
    colorLow?: string,
    className?: string,
})
{
    const normal = Math.floor((props.current / props.max) * 100);

    // Display:
    let display: string[] = [
        props.name + (props.showValue || props.showPercentage ? ":" : ""),
    ];
    if (props.showValue) {
        display.push(`${props.current}/${props.max}${props.unit ?? ""}`);
    }
    if (props.showPercentage) {
        display.push(`${normal}%`);
    }

    // Color:
    let color: string = props.color ?? (variables.colors.positive + "AA");
    if (props.colorLow && normal <= 25) {
        color = props.colorLow;
    } else if (props.colorMed && normal <= 50) {
        color = props.colorMed;
    }

    // Component:
    const classes = styles();
    return (
        <div className={`${classes.barBackground} ${props.className ?? ""}`}>
            <div className={classes.barForeground} style={{
                width: `${normal}%`,
                backgroundColor: color,
            }} />
            <span>{display.join(" ")}</span>
        </div>
    );
}