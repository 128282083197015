import { useState } from "react";

import { digimonService } from "services";

import { DigimonDefDto } from "dtos/digimon.dto";
import { DigimonState } from "actors/digimon.actor";

import widgets from "styles/widgets";

export default function Hatch(props: {
    digimon: DigimonState,
})
{
    // Digitama State:
    const [state, setState] = useState({
        hatching: false,
        confirmed: false,
    });

    // Actions:
    const confirmHatch = async () => {
        // Hatching:
        if (state.hatching) {
            return;
        }
        setState({
            ...state,
            hatching: true,
        });

        // Get Evolution:
        const digimonDefDto: DigimonDefDto = await digimonService.getDefinition(props.digimon.dto.name);
        const evolutionName: string | undefined = digimonDefDto?.evolutions[0] ?? undefined;
        if (!evolutionName) {
            console.error(`[Hatch] Unable to find hatch evolution for ${props.digimon.dto.name}.`);
            console.log(digimonDefDto);
            setState({
                ...state,
                hatching: false,
            });
            return;
        }

        // Create Evolution:
        digimonService.createEvolution({
            name: evolutionName,
        });
        setState({
            ...state,
            confirmed: true,
        });
    };

    // Component:
    const widgetClasses = widgets();
    let component = (<></>);
    if (!state.confirmed) {
        component = (
            <div className={`${widgetClasses.popup} ${widgetClasses.form}`}>
                <h1>It's Hatching!</h1>
                <p>Your Digitama is ready to hatch!</p>
                <button className={widgetClasses.confirm} onClick={() => confirmHatch()}>
                    Hatch
                </button>
            </div>
        );
    }
    return component;
}