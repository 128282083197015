import { GameState } from "actors/game.actor";
import { UserState } from "actors/user.actor";
import { DigimonState } from "actors/digimon.actor";

import { setGui } from "components/app";
import { GuiState } from "states/gui.state";
import Popup from "components/gui/popup";
import Travel from "components/gui/travel";
import ActionButton from "components/widgets/action-button";
import { gameActor, userService } from "services";

export default function ExploreActions(props: {
    gui: GuiState,
    game: GameState,
    user?: UserState,
    digimon?: DigimonState,
})
{
    // Actions:
    const home = () => {
        setGui({
            popupName: "home",
            popup: (<Popup
                title="Return Home"
                description="Return back to your home?"
                confirmText="Return Home"
                confirmAction={() => {
                    if (props.user) {
                        userService.action({
                            id: props.user.dto.id,
                            action: "travel",
                            value: "home",
                        });
                    }
                    setGui({}, true);
                }}
                cancelText="Cancel"
                cancelAction={() => setGui({}, true)}
            />),
        });
    }

    const migrate = () => {
        setGui({
            popupName: "migrate",
            popup: (<Popup
                title="Migrate"
                description="Change your home to this region? You can always move back to the previous region by travelling there and migrating again any time."
                confirmText="Migrate"
                confirmAction={() => {
                    if (props.user) {
                        userService.action({
                            id: props.user.dto.id,
                            action: "migrate",
                            value: "confirm",
                        });
                    }
                    setGui({}, true);
                }}
                cancelText="Cancel"
                cancelAction={() => setGui({}, true)}
            />),
        });
    }

    const travel = () => {
        setGui({
            popupName: "travel",
            popup: (<Travel
                game={props.game}
            />),
        });
    }
    
    // Component:
    let buttons: JSX.Element[] = [];
    if (props.user) {
        if (!props.game.homeZone) {
            buttons.push((
                <ActionButton
                    key="home"
                    icon="home"
                    name="Home"
                    description="Return home."
                    active={false}
                    onClick={home}
                />
            ));
            buttons.push((
                <ActionButton
                    key="migrate"
                    icon="migrate"
                    name="Migrate"
                    description="Move your home to this region."
                    active={false}
                    onClick={migrate}
                />
            ));
        }
        buttons.push((
            <ActionButton
                key="travel"
                icon="travel"
                name="Travel"
                description="Travel to a different region."
                active={false}
                onClick={travel}
            />
        ));
    }
    return (
        <>
            {buttons}
        </>
    );
}