import { Socket } from "socket.io-client"

import { gameActor } from "services";
import { AbstractService } from "services/abstract.service";
import { DigimonDto, DigimonRequestDto, DigimonDefIndexDto, DigimonDefIndexRequestParams, DigimonDefDto, DigimonActionDto } from "dtos/digimon.dto";
import { EvolutionRequestDto } from "dtos/evolution.dto";
import { ErrorDto } from "dtos/error.dto";

export class DigimonService extends AbstractService<DigimonDto>
{
    /**
     * Constructor
     * @param socket The connection socket.
     */
    public constructor(socket: Socket)
    {
        super(socket);

        // Digimon:
        this.socket.on('digimon', (data: DigimonDto) => {
            // console.log(`[Digimon Service] Digimon ${data.name} updated.`);
            this.listeners.forEach(listener => listener('digimon', data));
        });

        this.socket.on('digimon.remove', (data: DigimonDto) => {
            this.listeners.forEach(listener => listener('digimon.remove', data));
        });

        this.socket.on('digimon.destroy', (data: DigimonDto) => {
            this.listeners.forEach(listener => listener('digimon.destroy', data));
        });

        this.socket.on('digimon.error', (data: ErrorDto) => {
            console.error(`[Digimon Service] ${data.message}`);
        });
        this.socket.on('digimon.action.error', (data: ErrorDto) => {
            console.error(`[Digimon Service] ${data.message}`);
        });
    }

    /**
     * Sends an index definitions request.
     * @param params Optional params to filter the index by, etc.
     */
    public async indexDefinitions(params?: DigimonDefIndexRequestParams): Promise<DigimonDefIndexDto>
    {
        console.log("[Digimon Service] Requesting definitions index...");
        return await this.api.get("digimon-defs", {
            params: {
                ...params,
                admin: gameActor.userActor?.dto.admin ? true : params?.admin
            },
        }).then(response => {
            console.log(`[Digimon Service] Received ${response.data.items?.length} definition(s).`);
            return response.data
        });
    }

    /**
     * Sends a sigle definition request.
     * @param name The name of the definition to get.
     */
    public async getDefinition(name: string): Promise<DigimonDefDto>
    {
        console.log(`[Digimon Service] Requesting ${name} definition...`);
        return await this.api.get("digimon-defs/" + name,).then(response => {
            console.log(`[Digimon Service] Received ${response.data.name} definition.`);
            return response.data
        });
    }

    /**
     * Sends a digimon create message.
     * @param dto The message data to send.
     */
    public create(dto: DigimonRequestDto): void
    {
        console.log("[Digimon Service] Creating digimon...");
        this.socket.emit('digimon.create', dto);
    }

    /**
     * Sends an evolution create message.
     * @param dto The message data to send.
     */
    public createEvolution(dto: EvolutionRequestDto): void
    {
        console.log("[Digimon Service] Creating evolution...");
        this.socket.emit('evolution.create', dto);
    }

    /**
     * Sends a digimon action message.
     * @param dto The message data to send.
     */
    public action(dto: DigimonActionDto): void
    {
        console.log(`[Digimon Service] Sending ${dto.action} ${dto.value} action...`);
        this.socket.emit('digimon.action', dto);
    }

    /**
     * Gets the display name of the provided mood.
     * @return The display name of the provided mood.
     */
    public getMoodDisplay(mood: string): string
    {
        switch (mood) {
            case "fraught":
                return "Fraught";
            case "relaxed":
                return "Relaxed";
            case "stressed":
                return "Stressed";
            case "angry":
                return "Angry";
            case "wistful":
                return "Wistful";
            case "anxious":
                return "Anxious";
            case "depressed":
                return "Depressed";
        }
        return "Jolly";
    }

    /**
     * Gets the description of the provided mood.
     * @return The description of the provided mood.
     */
    public getMoodDescription(mood: string): string
    {
        switch (mood) {
            case "fraught":
                return "Your Digimon is uncomfortable. Check that they're well fed and that their home isn't too messy or clean.";
            case "relaxed":
                return "Your Digimon is content but needs to rest, avoid playing or battling too much. Sleeping will also speed up energy recovery but slow down growth.";
            case "stressed":
                return "Your Digimon is feels neglected. They're either worried about not seeing you enough, playing enough, winning battles or not eating preferred food.";
            case "angry":
                return "Your Digimon is uncomfortable and feels neglated. Check that they're well fed and that their home isn't too messy or clean. They're worried from either not seeing you enough, playing enough, winning battles or not eating preferred food.";
            case "wistful":
                return "Your Digimon is uncomfortable and tired. Check that they're well fed and that their home isn't too messy or clean. Also give them some time to rest, playing or battling too much can be quite tiring.";
            case "anxious":
                return "Your Digimon is uncomfortable and feels neglated. Check that they're well fed and that their home isn't too messy or clean. They're worried from either not seeing you enough, playing enough, winning battles or not eating preferred food.";
            case "depressed":
                return "Your Digimon is miserable! They feel uncomfortable, tired and neglected! Check that they're well fed and that their home isn't too messy or clean. They're worried from either not seeing you enough, playing enough, winning battles or not eating preferred food. Also give them some time to rest, playing or battling too much can be quite tiring.";
        }
        return "Your Digimon is happy, comfortable and full of energy!";
    }
}