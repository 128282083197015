import { useEffect, useState } from 'react';
import { createUseStyles } from 'react-jss';

import { gameActor } from 'services';

import { GuiState } from "states/gui.state";
import Game from "components/game/game";
import GUI from "components/gui/gui";
import HUD from "components/gui/hud";

const styles = createUseStyles({
    app: {
        overflow: 'hidden',
        background: 'black',
        width: '100%',
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    wrapper: {
        position: 'relative',
        width: "100vw",
        height: "100vh",
    },
});

export let setGui = (newGuiState: GuiState, clear: boolean = false) => {};

export default function App()
{
    // States:
    const [gameState, setGameState] = useState(gameActor.getState());
    useEffect(() => gameActor.addTickListener(() => {
        setGameState(gameActor.getState());
    }));
    const [guiState, setGuiState] = useState({} as GuiState);

    // Set GUI:
    setGui = (newGuiState: GuiState, clear: boolean = false) => {
        if (clear) {
            setGuiState(newGuiState);
            return;
        }
        setGuiState({
            ...guiState,
            ...newGuiState
        });
    };

    // Component:
    const classes = styles();
    return (
        <div className={classes.app}>
            <div className={classes.wrapper}>
                <Game game={gameState} />
                {gameState.mainUser && gameState.mainDigimon && (
                    <HUD digimon={gameState.mainDigimon} />
                )}
                <GUI game={gameState} gui={guiState} />
            </div>
        </div>
    );
}