import { UserDto } from "dtos/user.dto";
import { GameActor } from "actors/game.actor";
import { ItemDefDto } from "dtos/item.dto";
import { Vector3Dto } from "dtos/vector3.dto";
import { itemService } from "services";

export interface UserState
{
    dto: UserDto,
    action: string,
    item: ItemDefDto | null,
}

export class UserActor
{
    public gameActor: GameActor;
    public dto: UserDto;
    public action: string = "";
    public item: ItemDefDto | null = null;

    /**
     * Constructor
     * @param gameActor The game actor that this actor belongs to.
     * @param userDto The data to initialise this actor with.
     */
    public constructor(gameActor: GameActor, userDto: UserDto)
    {
        this.gameActor = gameActor;
        this.dto = userDto;
        console.log("[User] Created user actor with data:", userDto);
    }

    /**
     * Returns the current game state.
     */
    public getState(): UserState
    {
        return {
            dto: this.dto,
            action: this.action,
            item: this.item,
        };
    }

    /**
     * Updates the dto received for this user.
     * @param dto The data transfer object.
     */
    public update(dto: UserDto): void
    {
        this.dto = dto;
    }

    /**
     * The main tick function of this actor.
     */
    public tick(): void
    {
        // Nothing to tick for users yet.
    }

    /**
     * Called when the user clicks in the world.
     * @param clickX The x position of the click.
     * @param clickY The y position of the click.
     */
    public onClick(clickX: number, clickY: number): void
    {
        const layerX: number = (clickX - this.gameActor.vw(50)) / this.gameActor.vw(100);
        const layerY: number = (clickY - this.gameActor.vh(70)) / this.gameActor.vh(100);
        const zoneRange: Vector3Dto = {
            x: this.gameActor.zone?.size.x ?? 100,
            y: 0,
            z: this.gameActor.zone?.size.z ?? 100,
        };
        const position: Vector3Dto = {
            x: Math.min(zoneRange.x ?? 100, Math.max(-zoneRange.x, layerX * 250 * this.gameActor.zoneScale.x)),
            y: 0,
            z: Math.min(zoneRange.z ?? 100, Math.max(-zoneRange.z, layerY * -1000 * this.gameActor.zoneScale.z)),
        };
        
        switch (this.action) {
            case "food":
                if (this.item) {
                    itemService.create({
                        name: this.item.name,
                        position,
                    });
                    this.holdItem(null);
                    if (this.gameActor.getState().itemTypeCounts["food"] >= 2) { // Check for 2 items instead of 3 due to network latency.
                        this.setAction("");
                    }
                }
                break;
            case "toys":
                if (this.item) {
                    itemService.create({
                        name: this.item.name,
                        position,
                    });
                    this.holdItem(null);
                    this.setAction("");
                }
                break;
        }
    }

    /**
     * Sets the current action of this user.
     * Note: This will clear any held item.
     * @param action The action to set to.
     */
    public setAction(action: string): void
    {
        this.item = null;
        this.action = action;
    }

    /**
     * Picks up and holds the provided item by definition.
     * @param item The item to hold, null to clear.
     */
    public holdItem(item: ItemDefDto | null): void
    {
        this.item = item;
    }

    /**
     * Generates display text for this user's name.
     * @return Display text for this user's name.
     */
    public nameDisplay(): string
    {
        return this.dto.username;
    }
}