import { useEffect, useState } from "react";
import { createUseStyles } from "react-jss";

import variables from "styles/variables";

const styles = createUseStyles<any, {
    mouseX: number,
    mouseY: number,
    flipX: boolean,
    flipY: boolean,
    color: string,
    lines: number,
}>({
    container: {
        position: "fixed",
        left: (props) => !props.flipX ? props.mouseX + 16 : props.mouseX - 32 - 300,
        // right: (props) => props.flipX ? props.mouseX - 16 : undefined,
        top: (props) => props.mouseY - (props.flipY ? (24 * props.lines) + 20 : 0),
        zIndex: 1024,
        width: 300,
        padding: 8,
        borderStyle: "solid",
        borderColor: (props) => props.color,
        backgroundColor: (props) => props.color + "CC",
        backgroundSize: "contain",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
        color: variables.colors.background,
        fontSize: "0.9em",
        textShadow: "-1px 0 black, 0 1px black, 1px 0 black, 0 -1px black",
        "& h3": {
            fontSize: "1.2em",
            margin: 0,
        },
        "& p": {
            margin: 0,
        },
    },
});

export default function HoverTooltip(props: {
    hover: {
        title: string,
        description: string,
        color?: string,
        metadata?: {
            key: string,
            name: string,
        }[],
    },
})
{
    // States:
    const [mouseState, setMouseState] = useState({
        mouseX: -1000,
        mouseY: -1000,
        flipX: false,
        flipY: false,
    });
    let lines: number = 1;

    // Mouse:
    useEffect(() => {
        const handleMouseMove = (event: MouseEvent) => {
            setMouseState({
                mouseX: event.clientX,
                mouseY: event.clientY,
                flipX: event.clientX > (window.innerWidth * 0.5),
                flipY: event.clientY > (720 * 0.5),
            });
        };
        window.addEventListener("mousemove", handleMouseMove);
        return () => {
            window.removeEventListener("mousemove", handleMouseMove);
        };
    }, []);

    // Classes:
    const classes = styles({
        ...mouseState,
        color: props.hover.color ?? variables.colors.neutral,
        lines: lines + 1 + Math.floor(props.hover.description.length / 32),
    });

    // Metadata:
    let metadata: JSX.Element | JSX.Element[] = props.hover.metadata?.map(entry => (
        <p key={entry.key}>{entry.name}</p>
    )) ?? (<></>);

    // Component:
    return (
        <div className={`${classes.container}`}>
            <h3>{props.hover.title}</h3>
            <p>{props.hover.description}</p>
            {metadata}
        </div>
    );
}