import { createUseStyles } from "react-jss";

import { setGui } from "components/app";
import { GameState } from "actors/game.actor";
import { ItemActor, ItemState } from "actors/item.actor";
import { gameActor } from "services";

export default function Item(props: {
    game: GameState,
    item: ItemState,
    itemActor: ItemActor,
})
{
    // Mouse:
    const mouseEnter = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        setGui({
            hoverItem: props.item,
        });
    };
    const mouseLeave = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        setGui({
            hoverItem: null,
        });
    };
    const click = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        setGui({
            hoverItem: null,
        });
        props.itemActor.onClick();
    };

    // Animated Styles:
    const styles = createUseStyles<any, {
        game: GameState,
        item: ItemState,
        scale: number,
    }>({
        item: {
            pointerEvents: "all",
            position: "absolute",
            
            left: (props) => `calc(${50 + (props.item.position.x * 0.4 / (props.game.zoneScale.x))}vw - ${props.item.width * 0.5}px)`,
            bottom: (props) => 30 + props.item.position.y + (props.item.position.z * 0.1)  + "vh",
            zIndex: (props) => 100 - Math.round(props.item.position.z),
            
            width: (props) => props.item.height,
            height: (props) => props.item.height,
            transformOrigin: "bottom",
            transform: (props) => `
                scale(${0.6 + (0.4 * ((-props.item.position.z + 100) / 100))})
                scale(${props.scale * (1 / props.game.zoneScale.x)})
            `,
    
            backgroundImage: (props) => `url(assets/items/${props.item.dto.type}/${props.item.dto.name}.png)`,
            backgroundSize: (props) => props.item.height,
            backgroundRepeat: "no-repeat",
            backgroundPosition: "top",
            imageRendering: "pixelated",
    
            animation: "$sprite-animation",
            animationDuration: 500,
            animationIterationCount: "infinite",
            animationTimingFunction:  (props) => `steps(${props.item.animationFrames})`,
            animationFillMode: "both",
    
            "&:hover": {
                filter: "brightness(1.5)",
            },
        },
        "@keyframes sprite-animation": {
            from: {
                backgroundPositionY: 0,
            },
            to: {
                backgroundPositionY: -props.item.height * props.item.animationFrames,
            },
        },
    });

    // Component:
    const classes = styles({
        ...props,
        scale: gameActor.vh(8) / 24,
    });
    return (
        <div className={classes.item} onClick={click} onMouseEnter={mouseEnter} onMouseLeave={mouseLeave} />
    );
}