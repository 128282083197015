import { UserState } from "actors/user.actor";
import ActionButton from "components/widgets/action-button";

import { gameActor, itemService } from "services";
import { GuiState } from "states/gui.state";
import { GameState } from "actors/game.actor";
import { DigimonState } from "actors/digimon.actor";
import variables from "styles/variables";
import { ItemDefDto } from "dtos/item.dto";

export default function FoodActions(props: {
    gui: GuiState,
    game: GameState,
    user?: UserState,
    digimon?: DigimonState,
})
{
    // Actions:
    const holdFood = (itemDefDto: ItemDefDto) => {
        if (props.user!.item?.name === itemDefDto.name && props.user!.item?.type === itemDefDto.type) {
            gameActor.userActor?.holdItem(null);
            return;
        }
        gameActor.userActor?.holdItem(itemDefDto);
    }

    // Component:
    const buttons: JSX.Element[] = itemService.foodItems.map(itemDefDto => (
        <ActionButton
            key={itemDefDto.name}
            item={itemDefDto.name}
            type={itemDefDto.type}
            name={itemDefDto.display}
            description={itemService.getDescription(itemDefDto.name, itemDefDto.type, props.digimon?.dto)}
            metadata={itemService.getHighs(itemDefDto)}
            active={props.user!.action === "food" && props.user!.item?.name === itemDefDto.name && props.user!.item?.type === itemDefDto.type}
            onClick={() => holdFood(itemDefDto)}
            tooltipColor={itemService.getColor(itemDefDto.type)}
            backgroundColor={
                props.digimon?.dto.foodPreferences?.likes.includes(itemDefDto.name) ? variables.colors.positive + "88" :
                props.digimon?.dto.foodPreferences?.hates.includes(itemDefDto.name) ? variables.colors.negative + "88" :
                undefined
            }
        />
    ));
    return props.user?.action === "food" ? (
        <>{buttons}</>
    ) : (<></>);
}