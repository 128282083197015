import { createUseStyles } from "react-jss";

import { gameActor } from 'services';
import { GameState } from "actors/game.actor";

import Digimon from "components/game/digimon";
import Item from "components/game/item";

import Grassland from "components/game/regions/grassland";
import Desert from "components/game/regions/desert";
import Reefs from "components/game/regions/reefs";

const styles = createUseStyles<any, GameState>({
    world: {
        pointerEvents: "all",
        position: "absolute",
        left: "0vw",
        right: "0vw",
        top: "0vh",
        bottom: "0vh",
        transform: "scale(1)",
    },
});


export default function Game(props: {
    game: GameState,
})
{
    const actorComponents: JSX.Element[] = [];

    // Digimon:
    Object.keys(props.game.digimon).forEach(id => actorComponents.push((
        <Digimon key={id} game={props.game} digimon={props.game.digimon[id]} digimonActor={gameActor.digimonActors[id]} />
    )));

    // Items:
    Object.keys(props.game.items).forEach(id => actorComponents.push((
        <Item key={id} game={props.game} item={props.game.items[id]} itemActor={gameActor.itemActors[id]} />
    )));

    // Mouse:
    const click = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        gameActor.userActor?.onClick(event.clientX, event.clientY);
    };

    // Regions:
    let regionComponent: JSX.Element;
    switch (props.game.zone?.region.name ?? "grasslands") {
        case "desert":
            regionComponent = (<Desert game={props.game} actorComponents={actorComponents} />);
            break;
        case "reefs":
            regionComponent = (<Reefs game={props.game} actorComponents={actorComponents} />);
            break;
        default:
            regionComponent = (<Grassland game={props.game} actorComponents={actorComponents} />);
    }

    const classes = styles(props.game);
    return (
        <div className={classes.world} onClick={click}>
            {regionComponent}
        </div>
    );
}