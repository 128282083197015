import { Vector3, Vector3Dto } from "dtos/vector3.dto";
import { DigimonDto } from "dtos/digimon.dto";
import { GameActor } from "actors/game.actor";

export interface DigimonState
{
    dto: DigimonDto,
    animationDuration: number,
    animationFrame: number,
    animationState: string,
    emote: boolean,
    width: number,
    height: number,
    scale: number,
    position: Vector3Dto,
}

export class DigimonActor
{
    public gameActor: GameActor;
    public dto: DigimonDto;

    public animationDuration: number = 750; // The duration in ms per animation frame.
    public animationFrame: number = 0; // The current animation sprite frame to display.
    public animationState: string = "idle"; // The current animation state (idle, sleeping, etc).
    public width: number = 64;
    public height: number = 64;
    public scale: number = 0;
    public position: Vector3;
    public positionTarget?: Vector3;
    public emoteSeconds: number = 0;

    /**
     * Constructor
     * @param gameActor The game actor that this actor belongs to.
     * @param digimonDto The data to initialise this actor with.
     */
    public constructor(gameActor: GameActor, digimonDto: DigimonDto)
    {
        this.gameActor = gameActor;
        this.dto = digimonDto;
        this.position = Vector3.fromDto(digimonDto.position);
        console.log("[Digimon] Created digimon actor with data:", digimonDto);
    }

    /**
     * Returns the current game state.
     */
    public getState(): DigimonState
    {
        return {
            dto: this.dto,
            animationDuration: this.animationDuration,
            animationFrame: this.animationFrame,
            animationState: this.animationState,
            emote: !!this.emoteSeconds,
            width: this.width,
            height: this.height,
            scale: this.scale,
            position: this.position,
        };
    }

    /**
     * Updates the dto received for this digimon.
     * @param dto The data transfer object.
     */
    public update(dto: DigimonDto): void
    {
        this.dto = dto;
        this.positionTarget = dto.positionTarget ? Vector3.fromDto(dto.positionTarget) : undefined;
        switch(dto.level) {
            case 0:
                this.width = 64;
                this.height = 64;
                this.scale = this.gameActor.vh(8) / 32;
                break;
            case 1:
                this.width = 64;
                this.height = 64;
                this.scale = this.gameActor.vh(8) / 32;
                break;
            case 2:
                this.width = 92;
                this.height = 92;
                this.scale = this.gameActor.vh(8) / 24;
                break;
            case 3:
                this.width = 128;
                this.height = 128;
                this.scale = this.gameActor.vh(8) / 32;
                break;
            default:
                this.width = 256;
                this.height = 256;
                this.height = this.gameActor.vh(8) / 64;
                break;
        }
    }

    /**
     * The main tick function of this actor.
     */
    public tick(): void
    {
        // AI:
        this.move();

        // Emote:
        if (this.gameActor.onSecond && this.dto.level > 0) {
            if (this.emoteSeconds > 0) {
                this.emoteSeconds--;
            } else if (this.gameActor.seconds % 60 === 0) {
                this.emoteSeconds = 4;
            }
        }

        // Animation State:
        this.animationState = "idle";
        if (this.positionTarget) {
            this.animationState = "run";
        }
        if (this.dto.conditions.includes("sleeping")) {
            this.animationState = "sleep";
        }

        // Animation Frame:
        const ms: number = this.gameActor.ticks * this.gameActor.fps;
        const frames: number = Math.floor((ms / this.animationDuration) * 4);
        this.animationFrame = Math.abs((frames % 4) - 1);
        if (this.dto.goal === "eat" && ms % 1000 <= 250) {
            this.animationFrame = 3;
        }

        // Animations:
        this.animationDuration = 1250;
        switch (this.animationState) {
            case "run":
                this.animationDuration *= (1 - (this.dto.stats.speed * 0.01)) * 0.5;
                break;
            case "sleep":
                this.animationDuration *= 3;
                this.animationFrame = 4;
                break;
        }

        // Egg Animation Speed:
        if (this.dto.level === 0) {
            const growthNormal: number = this.dto.stats.growth / this.dto.stats.growthMax;
            if (growthNormal === 1) {
                this.animationDuration = 100;
            } else if (growthNormal >= 0.75) {
                this.animationDuration = 250;
            } else if (growthNormal >= 0.5) {
                this.animationDuration = 500;
            } else if (growthNormal >= 0.25) {
                this.animationDuration = 750;
            } else {
                this.animationDuration = 1000;
            }
        }
    }

    /**
     * Moves this digimon towards any target position it has.
     */
    public move(): void
    {
        // Falling:
        if (this.position.y > 0) {
            this.position.y--;
        }

        // Target Position:
        if (!this.positionTarget) {
            return;
        }
        if (this.position.distanceTo(this.positionTarget) <= 0.1) {
            this.positionTarget = undefined;
            return;
        }

        const distX: number = this.positionTarget.x - this.position.x;
        const distZ: number = this.positionTarget.z - this.position.z;
        const distXZ: number = Math.sqrt((distX * distX) + (distZ * distZ));

        // Target Reached:
        if (distXZ <= 0.1) {
            this.position.x = this.positionTarget.x;
            this.position.z = this.positionTarget.z;
            return;
        }

        // Movement:
        let speed: number = Math.min(this.dto.stats.speed, distXZ);
        this.position.x += (distX / distXZ) * speed;
        this.position.z += (distZ / distXZ) * speed;
    }

    /**
     * Called when this actor is clicked.
     */
    public onClick(): void
    {
        if (this.dto.level > 0) {
            this.emoteSeconds = 4;
            this.position.y = Math.max(this.position.y, 4);
        }
    }

    /**
     * Generates display text for this digimon's name.
     * @return Display text for this digimon's name.
     */
    public nameDisplay(): string
    {
        return this.dto.display;
    }
}