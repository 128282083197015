import { UserState } from "actors/user.actor";
import { GuiState } from "states/gui.state";
import { GameState } from "actors/game.actor";
import { DigimonState } from "actors/digimon.actor";

import { setGui } from "components/app";
import ActionButton from "components/widgets/action-button";
import Stats from "components/gui/stats";

export default function StatsButton(props: {
    gui: GuiState,
    game: GameState,
    user?: UserState,
    digimon?: DigimonState,
})
{
    // Actions:
    const stats = () => {
        // Close Stats:
        if (props.gui.popupName === "stats") {
            setGui({
                popupName: undefined,
                popup: undefined,
            });
            return;
        }

        // Open Stats:
        setGui({
            popupName: "stats",
            popup: <Stats user={props.user} digimon={props.digimon!} />,
        });
    }

    // Component:
    return props.digimon ? (
        <ActionButton
            icon="stats"
            name="Stats"
            description="View your Digimon's stats."
            active={props.gui.popupName === "stats"}
            onClick={stats}
        />
    ) : (<></>);
}