import { useEffect, useState } from "react";

import { digimonService } from "services";
import { DigimonState } from "actors/digimon.actor";

import widgets from "styles/widgets";
import { DigimonDefDto } from "dtos/digimon.dto";
import DigimonButton from "components/widgets/digimon-button";

export default function Evolve(props: {
    digimon: DigimonState,
})
{
    // States:
    const [state, setState] = useState({
        hidden: true,
        selected: "",
        type: "",
        evolutions: [] as DigimonDefDto[],
    });

    // Get Evolutions:
    useEffect(() => {
        let cancel: boolean = false;
        digimonService.indexDefinitions({
            from: props.digimon.dto.name,
        }).then(indexDto => {
            if (cancel) {
                return;
            }
            const evolutions: DigimonDefDto[] = indexDto.items ?? [];
            if (!evolutions.length) {
                console.warn(`[Evolve] Unable to find any evolutions for ${props.digimon.dto.name}.`);
                return;
            }
            setState({
                ...state,
                hidden: false,
                evolutions,
                type: "",
            });
        });
        return () => {
            cancel = true
        };
    }, [ props.digimon.dto.name ]);

    // Actions:
    const selectEvolution = (definition: DigimonDefDto) => {
        setState({
            ...state,
            selected: definition.name,
        });
    };

    const confirmEvolution = () => {
        // Hidden:
        if (state.hidden || !state.selected) {
            return;
        }
        setState({
            ...state,
            hidden: true,
        });

        // Create Evolution:
        digimonService.createEvolution({
            name: state.selected,
        });
    };

    // Evolution Buttons:
    const buttons: JSX.Element[] = state.evolutions.map(definition => (
        <DigimonButton
            key={definition.name}
            digimonDef={definition}
            selected={definition.name === state.selected}
            onClick={() => selectEvolution(definition)}
        />
    ));

    // Component:
    const widgetClasses = widgets();
    let component = (<></>);
    if (!state.hidden) {
        component = (
            <div className={`${widgetClasses.popup} ${widgetClasses.form}`}>
                <h1>Digivolution Unlocked!</h1>
                <p>Your Digimon has unlocked a new {state.type} Evolution level!</p>

                <div className={widgetClasses.grid}>
                   {buttons}
                </div>

                <button className={widgetClasses.confirm} onClick={() => confirmEvolution()}>
                    Digivolve!
                </button>
            </div>
        );
    }
    return component;
}