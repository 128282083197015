import { useState } from "react";

import { regionService, userService } from "services";
import { RegionDefDto } from "dtos/region.dto";
import { GameState } from "actors/game.actor";

import widgets from "styles/widgets";
import { setGui } from "components/app";
import RegionButton from "components/widgets/region-button";

export default function Travel(props: {
    game: GameState,
})
{
    // States:
    const [state, setState] = useState({
        selected: "",
    });

    // Actions:
    const selectRegion = (definition: RegionDefDto) => {
        setState({
            ...state,
            selected: definition.name,
        });
    };

    const confirmRegion = () => {
        // Hidden:
        if (!state.selected || !props.game.mainUser) {
            return;
        }

        setGui({}, true);

        // Travel to Region:
        userService.action({
            id: props.game.mainUser.dto.id,
            action: "travel",
            value: state.selected,
        });
    };

    // Region Buttons:
    const buttons: JSX.Element[] = regionService.regions.map(definition => (
        <RegionButton
            key={definition.name}
            regionDef={definition}
            selected={definition.name === state.selected}
            onClick={() => selectRegion(definition)}
        />
    ));

    // Component:
    const widgetClasses = widgets();
    let component = (<></>);
    component = (
        <div className={`${widgetClasses.popup} ${widgetClasses.form}`}>
            <h1>Travel</h1>
            <p>Select a region to explore:</p>

            <div className={widgetClasses.grid}>
                {buttons}
            </div>

            <div className={widgetClasses.row}>
                <button className={widgetClasses.confirm} onClick={() => confirmRegion()}>
                    Travel!
                </button>
                <button onClick={() => setGui({}, true)}>Cancel</button>
            </div>
        </div>
    );
    return component;
}