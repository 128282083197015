import { createUseStyles } from "react-jss";

import { setGui } from "components/app";

const styles = createUseStyles<any, {
    category: string,
    format: string,
    icon: string,
    width: any,
    height: any,
}>({
    icon: {
        pointerEvents: "all",
        width: (props) => props.width,
        height: (props) => props.height,
        backgroundImage: (props) => `url(assets/${props.category}/${props.icon}.${props.format})`,
        backgroundSize: "contain",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
        "&:hover": {
            filter: "brightness(1.5)",
        }
    },
});

export default function Icon(props: {
    icon: string,
    category?: string,
    name?: string,
    format?: string,
    description?: string,
    style?: {
        width: any,
        height: any,
    }
})
{
    // Mouse:
    const mouseEnter = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        if (!props.name || !props.description) {
            return;
        }
        setGui({
            hover: {
                title: props.name,
                description: props.description,
            },
        });
    };
    const mouseLeave = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        if (!props.name || !props.description) {
            return;
        }
        setGui({
            hover: null,
        });
    };
    const click = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        if (!props.name || !props.description) {
            return;
        }
        setGui({
            hover: null,
        });
    };

    // Component:
    const classes = styles({
        ...props,
        category: props.category ?? "icons",
        format: props.format ?? "svg",
        width: props.style?.width ?? 60,
        height: props.style?.height ?? 60,
    });
    return (
        <div className={`${classes.icon}`} onClick={click} onMouseEnter={mouseEnter} onMouseLeave={mouseLeave} />
    );
}