import { createUseStyles } from "react-jss";

import { GameState } from "actors/game.actor";
import { DigimonActor, DigimonState } from "actors/digimon.actor";

export default function Digimon(props: {
    game: GameState,
    digimon: DigimonState,
    digimonActor: DigimonActor,
})
{
    // Animated Styles:
    const styles = createUseStyles<any, {
        game: GameState,
        digimon: DigimonState,
    }>({
        digimon: {
            pointerEvents: (props) => props.game.mainUser?.action === "scoop" ? "none" : "all",
            position: "absolute",
            
            left: (props) => `calc(${50 + (props.digimon.position.x * 0.4 / (props.game.zoneScale.x))}vw - ${props.digimon.width * 0.5}px)`,
            bottom: (props) => 30 + props.digimon.position.y + (props.digimon.position.z * 0.1) + "vh",
            zIndex: (props) => 100 - Math.round(props.digimon.position.z),

            width: (props) => props.digimon.width,
            height: (props) => props.digimon.height,
            transformOrigin: "bottom",
            transform: (props) => `
                scale(${0.6 + (0.4 * ((-props.digimon.position.z + 100) / 100))})
                scale(${props.digimon.scale * (1 / props.game.zoneScale.x)})
            `,
    
            "&:hover": {
                filter: "brightness(1.25)",
            },
        },

        digimonRotation: {
            position: "absolute",
            width: "100%",
            height: "100%",
            transform: (props) => `
                scaleX(${Math.abs(props.digimon.dto.angles.y) > 90 ? -1 : 1})
            `,
        },
        
        sprite: {
            position: "absolute",
            width: "100%",
            height: "100%",

            backgroundImage: (props) => `url(assets/digimon/${props.digimon.dto.type}/${props.digimon.dto.name}.png)`,
            backgroundSize: (props) => props.digimon.height,
            backgroundRepeat: "no-repeat",
            backgroundPosition: "top",
            backgroundPositionY: (props) => props.digimon.animationFrame * -props.digimon.height,
            imageRendering: "pixelated",

            animationName: (props) => `$animation-${props.digimon.animationState}`,
            animationDuration: (props) => props.digimon.animationDuration,
            animationDirection: "alternate",
            animationIterationCount: "infinite",
            animationTimingFunction: "linear",
            animationFillMode: "both",
            transformOrigin: "bottom",
        },
        "@keyframes animation-idle": {
            from: {
                transform: `
                    scale(1)
                `,
            },
            to: {
                transform: `
                    scale(1.1)
                `,
            },
        },
        "@keyframes animation-run": {
            from: {
                transform: `
                    scale(1)
                    rotate(-10deg)
                `,
            },
            to: {
                transform: `
                    scale(1.1)
                    rotate(10deg)
                `,
            },
        },
        "@keyframes animation-sleep": {
            from: {
                transform: `
                    scale(0.9)
                `,
            },
            to: {
                transform: `
                    scale(1.1)
                `,
            },
        },

        emote: {
            position: "absolute",
            width: 32,
            height: 32,
            left: "calc(50% - 16px)",
            zIndex: 200,

            backgroundImage: (props) => `url(assets/moods/${props.digimon.dto.stats.mood}.png)`,
            imageRendering: "pixelated",
            animationName: "$animation-emote",
            animationDirection: "alternate",
            animationIterationCount: "infinite",
            animationTimingFunction: "linear",
            animationDuration: 250,
        },
        "@keyframes animation-emote": {
            from: {
                transform: `
                    scale(1)
                    translateY(0)
                `,
            },
            to: {
                transform: `
                    scale(1.1)
                    translateY(-4px)
                `,
            },
        },
    });

    const classes = styles({
        ...props,
    });
    return (
        <div className={classes.digimon} onClick={() => props.digimonActor.onClick()}>
            <div className={classes.digimonRotation}>
                <div className={classes.sprite} />
            </div>
            {props.digimon.emote && (<div className={classes.emote} />)}
        </div>
    );
}