import { UserState } from "actors/user.actor";
import ActionButton from "components/widgets/action-button";

import { digimonService } from "services";
import { GuiState } from "states/gui.state";
import { GameState } from "actors/game.actor";
import { DigimonState } from "actors/digimon.actor";

export default function SleepButton(props: {
    gui: GuiState,
    game: GameState,
    user?: UserState,
    digimon?: DigimonState,
})
{

    // Actions:
    const sleep = (enable: boolean) => {
        digimonService.action({
            id: props.digimon!.dto.id,
            action: "sleep",
            value: enable ? "enable" : "disable",
        });
    }

    // Component:
    return props.digimon && props.digimon.dto.level > 0 && props.game.homeZone ?
        props.digimon.dto.conditions.includes("sleeping") ? (
            <ActionButton
                icon="wake"
                name="Wake"
                description="Wake your Digimon back up. Best be ready with some food!"
                active={false}
                onClick={() => sleep(false)}
            />
        ) : (
            <ActionButton
                icon="sleep"
                name="Sleep"
                description="Send your Digimon to sleep. When sleeping, food draining and growth are much, much slower."
                active={false}
                onClick={() => sleep(true)}
            />
        ) : (<></>);
}