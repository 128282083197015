import { itemService } from "services";

import { Vector3, Vector3Dto } from "dtos/vector3.dto";
import { ItemDto } from "dtos/item.dto";
import { GameActor } from "actors/game.actor";
import { UserActor } from "./user.actor";
import { DigimonActor } from "./digimon.actor";

export interface ItemState
{
    dto: ItemDto,
    width: number,
    height: number,
    position: Vector3Dto,
    nameDisplay: string,
    typeDisplay: string,
    ownerName: string,
    animationFrames: number,
}

export class ItemActor
{
    public gameActor: GameActor;
    public dto: ItemDto;

    public width: number = 24;
    public height: number = 24;
    public position: Vector3;
    public positionTarget?: Vector3;

    /**
     * Constructor
     * @param gameActor The game actor that this actor belongs to.
     * @param itemDto The data to initialise this actor with.
     */
    public constructor(gameActor: GameActor, itemDto: ItemDto)
    {
        this.gameActor = gameActor;
        this.dto = itemDto;
        this.position = Vector3.fromDto(itemDto.position);
        console.log("[Item] Created item actor with data:", itemDto);
    }

    /**
     * Returns the current game state.
     */
    public getState(): ItemState
    {
        return {
            dto: this.dto,
            width: this.width,
            height: this.height,
            position: this.position,
            nameDisplay: this.nameDisplay(),
            typeDisplay: this.typeDisplay(),
            ownerName: this.ownerName(),
            animationFrames: this.dto.type === "poop" ? 3 : 0,
        };
    }

    /**
     * Updates the dto received for this item.
     * @param dto The data transfer object.
     */
    public update(dto: ItemDto): void
    {
        this.dto = dto;
        this.positionTarget = dto.positionTarget ? Vector3.fromDto(dto.positionTarget) : undefined;
    }

    /**
     * The main tick function of this actor.
     */
    public tick(): void
    {
        // AI:
        this.move();
    }

    /**
     * Moves this digimon towards any target position it has.
     */
    public move(): void
    {
        // Falling:
        if (this.position.y > 0) {
            this.position.y--;
        }

        // Target Position:
        if (!this.positionTarget) {
            return;
        }
        if (this.position.distanceTo(this.positionTarget) <= 0.1) {
            this.positionTarget = undefined;
            return;
        }

        const distX: number = this.positionTarget.x - this.position.x;
        const distZ: number = this.positionTarget.z - this.position.z;
        const distXZ: number = Math.sqrt((distX * distX) + (distZ * distZ));

        // Target Reached:
        if (distXZ <= 0.1) {
            this.position.x = this.positionTarget.x;
            this.position.z = this.positionTarget.z;
            return;
        }

        // Movement:
        let speed: number = Math.min(this.dto.speed, distXZ);
        this.position.x += (distX / distXZ) * speed;
        this.position.z += (distZ / distXZ) * speed;
    }

    /**
     * Called when this actor is clicked.
     */
    public onClick(): void
    {
        switch (this.dto.type) {
            case "food":
                if (this.gameActor.userActor?.action === "scoop") {
                    itemService.destroy({
                        id: this.dto.id,
                    });
                }
                break;
            case "poop":
                if (this.gameActor.userActor?.action === "scoop") {
                    itemService.destroy({
                        id: this.dto.id,
                    });
                }
                break;
            case "toy":
                if (this.gameActor.userActor?.action === "scoop") {
                    itemService.destroy({
                        id: this.dto.id,
                    });
                }
                break;
        }
    }

    /**
     * Generates display text for this item's name.
     * @return Display text for this item's name.
     */
    public nameDisplay(): string
    {
        return this.dto.display;
    }

    /**
     * Generates display text for this item's type.
     * @return Display text for this item's type.
     */
    public typeDisplay(): string
    {
        switch(this.dto.type) {
            case "food":
                return "Food";
            case "poop":
                return "Poop";
            default:
                return "Unknown";
        }
    }

    /**
     * Gets the name of this item's owner, if any.
     * @returns The name of this item's owner if any.
     */
    public ownerName(): string
    {
        // User:
        if (this.dto.userId && this.gameActor.userActors[this.dto.userId]) {
            return this.gameActor.userActors[this.dto.userId].nameDisplay();
        }

        // Digimon
        if (this.dto.digimonId && this.gameActor.digimonActors[this.dto.digimonId]) {
            const digimon: DigimonActor = this.gameActor.digimonActors[this.dto.digimonId];
            const owner: string | null = digimon.dto.owner;
            let prefix: string = owner ? owner + "'s" : "";
            if (owner === this.gameActor.userActor?.dto.username) {
                prefix = "Your ";
            }
            return prefix + digimon.nameDisplay();
        }

        return "Unknown's";
    }
}