export interface Vector3Dto
{
    x: number;
    y: number;
    z: number;
}

export class Vector3 implements Vector3Dto
{
    public static readonly ZERO = () => new Vector3(0, 0, 0);
    public static readonly ONE = () => new Vector3(1, 1, 1);

    public x!: number;
    public y!: number;
    public z!: number;

    /**
     * Creates a new Vector3 from a dto.
     * @param dto The dto to create from.
     * @returns A new vector 3.
     */
    public static fromDto(dto: Vector3Dto): Vector3
    {
        return new Vector3(dto.x, dto.y, dto.z);
    }

    /**
     * Constructor
     * @param x The x position.
     * @param y The y position.
     * @param z The z position.
     */
    public constructor(x: number, y: number, z: number)
    {
        this.x = x;
        this.y = y;
        this.z = z;
    }

    /**
     * Gets the distance from this vector to the target.
     * @param target The target vector to get the distance to.
     * @return The distance from this vector to the target vector.
     */
    public distanceTo(target: Vector3Dto): number
    {
        const distX: number = target.x - this.x;
        const distY: number = target.y - this.y;
        const distZ: number = target.z - this.z;
        return Math.sqrt((distX * distX) + (distY * distY) + (distZ * distZ));
    }
}