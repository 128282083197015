import { loginService, userService } from "services";
import { GameState } from "actors/game.actor";
import { UserState } from "actors/user.actor";
import { DigimonState } from "actors/digimon.actor";

import { setGui } from "components/app";
import { GuiState } from "states/gui.state";
import Popup from "components/gui/popup";
import ActionButton from "components/widgets/action-button";

export default function MenuActions(props: {
    gui: GuiState,
    game: GameState,
    user?: UserState,
    digimon?: DigimonState,
})
{
    // Actions:
    const fullscreen = () => {
        if (document.fullscreenElement) {
            document.exitFullscreen();
            return;
        }
        const html: HTMLElement = document.querySelector("html")!;
        html.requestFullscreen();
    }

    const logout = () => {
        setGui({
            popupName: "logout",
            popup: (<Popup
                title="Logout?"
                description="Leave the digital world and return to mundane reality?"
                confirmText="Logout"
                confirmAction={() => {
                    setGui({}, true);
                    loginService.logout();
                }}
                cancelText="Cancel"
                cancelAction={() => setGui({}, true)}
            />),
        });
    }

    const reset = () => {
        setGui({
            popupName: "reset",
            popup: (<Popup
                title="Reset Your Digimon?"
                description="You can reset your Digimon all the way back to a Digitama of your choice, however all progress will be lost, are you sure?"
                confirmText="Reset My Digimon"
                confirmAction={() => {
                    setGui({}, true);
                    userService.action({
                        id: props.user!.dto.id,
                        action: "reset",
                        value: "confirm",
                    });
                }}
                cancelText="Cancel"
                cancelAction={() => setGui({}, true)}
            />),
        });
    }
    
    // Component:
    let buttons: JSX.Element[] = [];
    if (props.user) {
        buttons.push((
            <ActionButton
                key="reset"
                icon="reset"
                name="Reset"
                description="Reset all progress and your Digimon back to a Digitama of your choice."
                active={false}
                onClick={reset}
            />
        ));
        buttons.push((
            <ActionButton
            key="logout"
                icon="logout"
                name="Logout"
                description="Log out of the digital world. Be sure to send your Digimon to sleep if you're going to be gone for a while!"
                active={false}
                onClick={logout}
            />
        ));
    }
    buttons.push((
        <ActionButton
            key="fullscreen"
            icon="fullscreen"
            name="Fullscreen"
            description="Toggles fullscreen mode for the ulimate Digimon immersion!"
            active={false}
            onClick={fullscreen}
        />
    ));
    return (
        <>
            {buttons}
        </>
    );
}