import { useEffect, useState } from "react";
import { createUseStyles } from "react-jss";

import { digimonService } from "services";
import { UserState } from "actors/user.actor";
import { DigimonDefDto } from "dtos/digimon.dto";
import { DigimonState } from "actors/digimon.actor";

import variables from "styles/variables";
import widgets from "styles/widgets";
import Icon from "components/widgets/icon";
import DigimonButton from "components/widgets/digimon-button";

const styles = createUseStyles<any, {
    action: string,
    itemName?: string,
    itemType?: string,
    mouseX: number,
    mouseY: number,
}>({
    container: {
        padding: 16,
        backgroundColor: variables.colors.foreground + "AA",
        color: variables.colors.background,
        "& h3": {
            textAlign: "center",
        }
    },
});

export default function Stats(props: {
    digimon: DigimonState,
    user?: UserState,
})
{
    // States:
    const [state, setState] = useState({
        evolutions: {} as Record<string, DigimonDefDto[]>,
    });

    // Evolutions:
    useEffect(() => {
        if (!props.digimon.dto.evolutions) {
            return;
        }

        // Fetch Evolution Digimon Defs:
        let cancel: boolean = false;
        digimonService.indexDefinitions({
            name: {
                in: props.digimon.dto.evolutions.map(evolution => evolution.name).join(","),
            },
        }).then(indexDto => {
            if (cancel) {
                return;
            }
            const digimonDefs: DigimonDefDto[] = indexDto.items ?? [];

            // No Digimon Defs:
            if (!digimonDefs.length) {
                console.warn(`[Stats] Unable to find any unlocked evolutions for ${props.digimon.dto.name}.`);
                return;
            }

            // Sort Digimon Defs into Evolutions:
            const evolutions: Record<string, DigimonDefDto[]> = digimonDefs
                .sort((a, b) => b.level - a.level)
                .reduce((group: Record<string, DigimonDefDto[]>, digimonDef: DigimonDefDto) => {
                    const key: string = digimonDef.level.toString();
                    if (!group[key]) {
                        group[key] = [];
                    }
                    group[key].push(digimonDef);
                    return group;
                }, {} as Record<string, DigimonDefDto[]>) ?? {};

            // Update Evolutions State:
            setState({
                evolutions,
            });
        });
        return () => {
            cancel = true
        };
    }, [ props.digimon.dto, ]);

    // Component:
    const widgetClasses = widgets();
    const classes = styles();
    return (
        <div className={`${widgetClasses.popup} ${classes.container}`}>
            <h3>{props.digimon.dto.display}</h3>
            <p>{props.user ? `Owner: ${props.user.dto.username}` : `Wild`}</p>

            {props.digimon.dto.level > 0 && (
                <div className={widgetClasses.row}>
                    <p>Mood:</p>
                    <Icon
                        name={digimonService.getMoodDisplay(props.digimon.dto.stats.mood ?? "jolly")}
                        description={digimonService.getMoodDescription(props.digimon.dto.stats.mood ?? "jolly")}
                        icon={props.digimon.dto.stats.mood ?? "jolly"}
                        category="moods"
                        format="png"
                        style={{
                            width: 32,
                            height: 32,
                        }}
                    />
                </div>
            )}
            
            <div className={widgetClasses.row}>
                <p>Evolutions:</p>
                {Object.keys(state.evolutions).map(level => (
                    <div key={level} className={widgetClasses.column}>
                        {state.evolutions[level].map(digimonDef => (
                            <DigimonButton key={digimonDef.name} digimonDef={digimonDef} size="small" tooltip={true} />
                        ))}
                    </div>
                )) ?? (<></>)}
            </div>

        </div>
    );
}