import axios, { AxiosInstance, AxiosResponse } from "axios";
import { Socket } from "socket.io-client"

import { AbstractService } from "services/abstract.service";

import { LoginDto as AccountsLoginDto, LoginRequestDto as AccountsLoginRequestDto } from "dtos/accounts/login.dto";

import { ErrorDto } from "dtos/error.dto";
import { LoginDto, LoginRequestDto } from "dtos/login.dto";
import { socketsService } from "services";

export class LoginService extends AbstractService<LoginDto>
{
    public readonly accountsApi: AxiosInstance;

    public errorListeners: ((event: string, data: any) => void)[] = [];
    public passwordResetListeners: ((event: string, data: any) => void)[] = [];

    /**
     * Constructor
     * @param socket The connection socket.
     */
    public constructor(socket: Socket)
    {
        super(socket);

        // Accounts API:
        this.accountsApi = axios.create({
            baseURL: process.env.REACT_APP_ACCOUNTS_URL + "/api/v2" ?? "",
        });

        // Connection (Auto Login):
        this.socket.on("connect", () => {
            const username: string | null = localStorage.getItem("username");
            const refreshToken: string | null = localStorage.getItem("refresh");
            if (username && refreshToken) {
                console.log(`[Login Service] Auto login as ${username} with refresh token...`);
                this.login({
                    grant_type: "refresh_token",
                    client_id: process.env.REACT_APP_ACCOUNTS_ID ?? "",
                    client_secret: process.env.REACT_APP_ACCOUNTS_SECRET ?? "",
                    username,
                    refresh_token: refreshToken,
                });
            }
        });

        // Login:
        this.socket.on('login.success', (dto: LoginDto) => {
            console.log("[Login Service] Login successful.");
            this.listeners.forEach(listener => listener('login.success', dto));
        });

        // Errors:
        this.socket.on('login.error', (dto: ErrorDto) => {
            console.warn(`[Login Service] Login error: ${dto.message}`);
            localStorage.removeItem("refresh");
            this.errorListeners.forEach(listener => listener('login.error', dto));
        });
    }

    /**
     * Adds an error event listener.
     * @param listener The event listener callback function to call.
     * @return Returns a callback to remove the event listener.
     */
    public addErrorListener(listener: (event: string, data: any) => void): () => void
    {
        this.errorListeners.push(listener);
        return () => this.errorListeners = this.errorListeners.filter(existingListener => existingListener !== listener);
    }

    /**
     * Attempts to login via an accounts service. Also updates local storage.
     * @param dto The accounts login dto to send.
     */
    public async login(dto: AccountsLoginRequestDto): Promise<void>
    {
        // Accounts Authentication:
        console.log("[Login Service] Authenticating...");
        let authResult: AxiosResponse<any, any> | undefined;
        try {
            authResult = await this.accountsApi.post("auth", dto);
        } catch (error: any) {
            let message: string = "Unable to access accounts service.";
            if (error.response?.data) {
                message = error.response.data.message ?? error.message;
                console.error(message);
            }
            console.error(error);
            this.errorListeners.forEach(listener => listener('login.error', {
                message,
            }));
            return;
        }
        const accountsLoginDto: AccountsLoginDto = authResult!.data;

        // Refresh Token:
        if (accountsLoginDto.refresh_token) {
            localStorage.setItem("username", accountsLoginDto.user.username);
            localStorage.setItem("refresh", accountsLoginDto.refresh_token);
        } else {
            localStorage.removeItem("refresh");
        }

        // JWT Login:
        const loginRequestDto: LoginRequestDto = {
            accessToken: accountsLoginDto.access_token,
        };
        console.log("[Login Service] Logging in...");
        this.socket.emit('login', loginRequestDto);
    }

    /**
     * Sends a logout message.
     */
    public async logout(): Promise<void>
    {
        console.log("[Login Service] Logging out...");
        localStorage.removeItem("refresh");
        this.socket.emit('logout', {});
        socketsService.disconnect();
    }
}