import { gameActor } from "services";
import { UserState } from "actors/user.actor";
import { GameState } from "actors/game.actor";
import { DigimonState } from "actors/digimon.actor";

import { setGui } from "components/app";
import { GuiState } from "states/gui.state";
import ActionButton from "components/widgets/action-button";

export default function ToysButton(props: {
    gui: GuiState,
    game: GameState,
    user?: UserState,
    digimon?: DigimonState,
    active: boolean,
})
{
    // Actions:
    const toys = () => {
        const action: string = props.user?.action === "toys" ? "" : "toys";
        gameActor.userActor?.setAction(action);
        setGui({
            submenu: props.gui.submenu === "toys" ? "" : "toys",
        });
    }

    // Component:
    return props.digimon && props.digimon.dto.level >= 2 ? (
        <ActionButton
            icon="toys"
            name="Toys"
            description="Play with your Digimon using various toys. (Be sure your Digimon isn't too tired)."
            active={props.active}
            onClick={() => toys()}
            disabled={props.game.ownedItemTypeCounts["toy"] >= 1}
        />
    ) : (<></>);
}