import { ChangeEvent, useEffect, useState } from "react";
import { createUseStyles } from "react-jss";

import { loginService } from "services";

import { ErrorDto } from "dtos/error.dto";

import widgets from "styles/widgets";

const styles = createUseStyles<any, any>({
    container: {
        width: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
    },
    logo: {
        width: "40vw",
        height: "30vh",
        backgroundImage: "url(logo.png)",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
        backgroundSize: "contain",
    },

    "@media screen and (max-width: 720px)": {
        logo: {
            width: "60vw",
            height: "20vh",
        },
    },
});

export default function Login()
{
    // Login State:
    const [loginState, setLoginState] = useState({
        view: "login",
        username: localStorage.getItem("username") ?? "",
        email: "",
        password: "",
        confirm: "",
        code: "",
        error: "",
    });

    // Set View
    const setView = (view: string, error?: string) => {
        setLoginState({
            ...loginState,
            view,
            error: error ?? "",
        });
    };

    // Event Listeners:
    useEffect(() => {
        // Login Status:
        const removeErrorListener = loginService.addErrorListener((event: string, data: ErrorDto) => {
            switch (event) {
                case "login.error":
                    setView("login_error", data.message);
                    break;
            }
        });
        return () => {
            removeErrorListener();
        }
    });

    // Input Handling:
    const handleInput = (event: ChangeEvent<HTMLInputElement>) => {
        setLoginState({
            ...loginState,
            [event.target.id]: event.target.value,
        });
    };
    const handleCheckbox = (event: ChangeEvent<HTMLInputElement>) => {
        setLoginState({
            ...loginState,
            [event.target.id]: !!event.target.value,
        });
    };

    // Actions:
    const login = () => {
        setView("logging_in");
        loginService.login({
            grant_type: "password",
            client_id: process.env.REACT_APP_ACCOUNTS_ID ?? "",
            client_secret: process.env.REACT_APP_ACCOUNTS_SECRET ?? "",
            username: loginState.username,
            password: loginState.password,
        });
    };

    const register = () => {
        window.open(process.env.REACT_APP_PORTAL_URL + "/register", "_blank")?.focus();
    };

    const forgotPassword = () => {
        window.open(process.env.REACT_APP_PORTAL_URL + "/forgot-password", "_blank")?.focus();
    };

    // Component:
    const classes = styles();
    const widgetClasses = widgets();
    let component;
    switch (loginState.view) {
        case "logging_in":
            component = (<>
                <h1>Logging In</h1>
                <p>Accessing user data from digital network...</p>
            </>);
            break;

        case "login_error":
            component = (<>
                <h1>Loggin Error</h1>
                <p>An error occurred while logging in: {loginState.error}</p>

                <div className={widgetClasses.row}>
                    <button onClick={() => setView("login")}>
                        Retry
                    </button>
                </div>
            </>);
            break;

        default:
            component = (<>
                <h1>Login</h1>

                <div className={widgetClasses.row}>
                    <label>Username</label>
                    <input id="username" value={loginState.username} onChange={handleInput} />
                </div>

                <div className={widgetClasses.row}>
                    <label>Password</label>
                    <input id="password" value={loginState.password} onChange={handleInput} type="password" />
                </div>

                <div className={widgetClasses.row}>
                    <button className={widgetClasses.confirm} onClick={login}
                        disabled={!loginState.username || !loginState.password}
                    >
                        Login with Nephrite
                    </button>
                    <button className={widgetClasses.info} onClick={forgotPassword}>
                        Forgot Password
                    </button>
                    <button className={widgetClasses.info} onClick={register}>
                        Register with Nephrite
                    </button>
                </div>
            </>);
    }

    return (
        <div className={`${classes.container}`}>
            {loginState.view === "login" && (<div className={`${classes.logo}`} />)}
            <div className={`${widgetClasses.popup} ${widgetClasses.form}`}>
                {component}
            </div>
        </div>
    );
}