import { UserState } from "actors/user.actor";
import { GameState } from "actors/game.actor";
import { DigimonState } from "actors/digimon.actor";

import { setGui } from "components/app";
import { GuiState } from "states/gui.state";
import ActionButton from "components/widgets/action-button";

export default function ExploreButton(props: {
    gui: GuiState,
    game: GameState,
    user?: UserState,
    digimon?: DigimonState,
    active: boolean,
})
{
    // Actions:
    const explore = () => {
        setGui({
            submenu: props.gui.submenu === "explore" ? "" : "explore",
        });
    }

    // Component:
    return props.digimon && props.digimon.dto.level >= 3 ? (
        <ActionButton
            icon="explore"
            name="Explore"
            description="Explore the Digital World!"
            active={props.active}
            onClick={() => explore()}
        />
    ) : (<></>);
}