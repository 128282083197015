import { createUseStyles } from "react-jss";

import { setGui } from "components/app";
import variables from "styles/variables";

const styles = createUseStyles<any, {
    icon: string,
    active: boolean,
    disabled: boolean,
    backgroundColor?: string,
}>({
    button: {
        pointerEvents: "all",
        width: (props) => props.active ? 80 : 60,
        height: (props) => props.active ? 80 : 60,
        margin: (props) => props.active ? 0 : 10,
        border: 16,
        borderStyle: "solid",
        borderRadius: "50%",
        backgroundColor: (props) => props.backgroundColor ?? variables.colors.foreground + "88",
        borderColor: variables.colors.foreground + "00",
        backgroundSize: "contain",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
        imageRendering: "pixelated",
        filter: (props) => `brightness(${props.disabled ? 0.25 : props.active ? 1 : 0.75}) contrast(${props.disabled ? 0.75 : props.active ? 1.5 : 1})`,
        "&:hover": {
            filter: (props) => `brightness(${props.disabled ? 0.5 : 1}) contrast(${props.disabled ? 0.75 : 1.25})`,
        }
    },
    icon: {
        width: "100%",
        height: "100%",
        backgroundImage: (props) => `url(assets/${props.icon})`,
        backgroundSize: "contain",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
    },

    "@media screen and (max-width: 1024px)": {
        button: (props) => ({
            width: props.active ? "7vh" : "5vh",
            height: props.active ? "7vh" : "5vh",
            margin: props.active ? 0 : 3,
        }),
    },

    "@media screen and (max-width: 720px)": {
        button: (props) => ({
            width: props.active ? 60 : 40,
            height: props.active ? 60 : 40,
            margin: props.active ? 0 : 3,
        }),
    },
});

export default function ActionButton(props: {
    icon?: string,
    item?: string,
    type?: string,
    name?: string,
    description?: string,
    metadata?: {
        key: string,
        name: string,
    }[],
    active: boolean,
    disabled?: boolean,
    tooltipColor?: string,
    backgroundColor?: string,
    onClick: () => void,
})
{
    // Mouse:
    const mouseEnter = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        if (props.name && props.description) {
            setGui({
                hover: {
                    title: props.name,
                    description: props.description,
                    color: props.tooltipColor,
                    metadata: props.metadata,
                },
            });
        }
    };
    const mouseLeave = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        setGui({
            hover: null,
        });
    };

    // Icon:
    let icon: string = `icons/${props.icon ?? ""}.svg`;

    // Item Icons:
    if (props.item && props.type) {
        icon = `items/${props.type}/${props.item}.png`;
    }

    // Mouse:
    const click = () => {
        if (props.disabled) {
            return;
        }
        props.onClick();
    };

    // Component:
    const classes = styles({
        icon,
        active: props.active,
        disabled: props.disabled ?? false,
        backgroundColor: props.backgroundColor,
    });
    return (
        <div className={`${classes.button}`} onClick={click} onMouseEnter={mouseEnter} onMouseLeave={mouseLeave}>
            <div className={`${classes.icon}`} />
        </div>
    );
}