import { createUseStyles } from "react-jss";

import { RegionDefDto } from "dtos/region.dto";

import { setGui } from "components/app";
import variables from "styles/variables";

const styles = createUseStyles<any, {
    name: string,
    size: string,
    hover: boolean,
}>({
    container: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: 'space-between',
        width: (props) => props.size === "small" ? 64 : 128,
        height: (props) => props.size === "small" ? 64 : 128,
        overflow: "hidden",
        margin: (props) => props.size === "small" ? 4 : 8,
        padding: (props) => props.size === "small" ? 0 : 16,
        borderRadius: 16,
        backgroundImage: (props) => `url(assets/regions/${props.name}/icon.png)`,
        backgroundSize: "contain",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "top",

        "&:hover": {
            filter: "brightness(1.1)",
        },
        "& > h1": {
            display: (props) => props.size === "small" ? "none" : "inline",
            marginBottom: 0,
            textAlign: 'center',
            fontSize: "0.9em",
            color: variables.colors.background,
            textShadow: "-1px 0 black, 0 1px black, 1px 0 black, 0 -1px black",
        },
    },
    selected: {
        filter: "brightness(1.25)",
        "&:hover": {
            filter: "brightness(1.3)",
        },
    },
});

export default function RegionButton(props: {
    regionDef: RegionDefDto,
    selected?: boolean,
    size?: string,
    tooltip?: boolean
    onClick?: () => void,
})
{
    // Mouse:
    const mouseEnter = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        if (!props.tooltip) {
            return;
        }
        setGui({
            hover: {
                title: props.regionDef.name,
                description: `Travel to the ${props.regionDef.name} region.`,
            },
        });
    };
    
    const mouseLeave = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        if (!props.tooltip) {
            return;
        }
        setGui({
            hover: null,
        });
    };

    const click = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        if (props.onClick) {
            props.onClick();
        }
        if (!props.tooltip) {
            return;
        }
        setGui({
            hover: null,
        });
    };

    // Component:
    const classes = styles({
        ...props.regionDef,
        size: props.size ?? "large",
        hover: !!props.onClick,
    });
    return (
        <div
            className={`${classes.container} ${props.selected ? classes.selected : ""}`}
            onClick={click}
            onMouseEnter={mouseEnter}
            onMouseLeave={mouseLeave}
        >
            <h1>{props.regionDef.name}</h1>
        </div>
    );
}