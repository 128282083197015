import { useEffect, useState } from "react";
import { createUseStyles } from "react-jss";

import { digimonService } from "services";
import { DigimonDefDto } from "dtos/digimon.dto";

import widgets from "styles/widgets";
import DigimonButton from "components/widgets/digimon-button";

const styles = createUseStyles<any, Record<string, any>>({
    previewRow: {
        alignItems: 'center',
        justifyContent: 'center',
    },
});

export default function Digitama()
{
    // Digitama State:
    const [state, setState] = useState({
        view: "select",
        selected: undefined as DigimonDefDto | undefined,
        fresh: [] as DigimonDefDto[],
        definitions: [] as DigimonDefDto[],
    });

    // Set View:
    const setView = (view: string) => {
        setState({
            ...state,
            view,
        });
    };

    // Event Listeners:
    useEffect(() => {
        // Get Tama Definitions:
        if (state.view === "select") {
            digimonService.indexDefinitions({
                type: "tama",
            }).then(index => setState({
                ...state,
                definitions: index.items,
            }));
        }
        
        // Get Fresh Definitions:
        else if (state.view === "confirm" && state.selected) {
            digimonService.indexDefinitions({
                name: {
                    in: state.selected.evolutions.join(","),
                },
            }).then(index => setState({
                ...state,
                fresh: index.items,
            }));
        }
    }, [ state.view ]);

    // Actions:
    const selectTama = (definition: DigimonDefDto) => {
        setState({
            ...state,
            selected: definition,
        });
    };
    const confirmTama = () => {
        setView("creating");
        digimonService.create({
            name: state.selected?.name ?? "",
        });
    };

    // Component:
    const classes = styles();
    const widgetClasses = widgets();
    let component;
    let view: string = state.view;
    if (view === "select" && !state.definitions?.length) {
        view = "loading";
    }
    switch(view) {

        case "select":
            component = (<>
                <h1>Choose A Digitama</h1>
                <p>You currently have no digimon, choose a Digitama to begin raising your Digimon!</p>

                <div className={widgetClasses.grid}>
                    {state.definitions.map(definition => (
                        <DigimonButton
                            key={definition.name}
                            digimonDef={definition}
                            selected={definition === state.selected}
                            onClick={() => selectTama(definition)}
                        />
                    ))}
                </div>

                <div className={widgetClasses.row}>
                    <button onClick={() => setView("confirm")} disabled={!state.selected}>
                        Select Digitama
                    </button>
                </div>
            </>);
            break;

        case "confirm":
            component = (<>
                <h1>Confirm Selection</h1>
                <div className={`${widgetClasses.row} ${classes.previewRow}`}>
                    {state.selected ? (<>
                        <DigimonButton
                            digimonDef={state.selected}
                            selected={true}
                        />
                        {state.fresh.map(definition => (
                            <DigimonButton
                                key={definition.name}
                                digimonDef={definition}
                            />
                        ))}
                    </>) : ""}
                </div>
                <p>Begin with this Digitama? You can always start with a new Digitama later but all progress will be reset.</p>

                <div className={widgetClasses.row}>
                    <button onClick={() => setView("select")}>
                        Change Selection
                    </button>
                    <button className={widgetClasses.confirm} onClick={() => confirmTama()}>
                        Confirm
                    </button>
                </div>
            </>);
            break;

        case "creating":
            component = (<>
                <h1>Syncronising</h1>
                <p>Syncronising biometrics and dynamis spectrum with digitama...</p>
            </>);
            break;

        default:
            component = (<>
                <h1>Choose A Digitama</h1>
                <p>Accessing Digitama database...</p>
            </>);
    }
    return (
        <div className={`${widgetClasses.popup} ${widgetClasses.form}`}>
            {component}
        </div>
    );
}