const variables = {
    colors: {
        background: "#EEEEEE",
        foreground: "#111122",
        positive: "#43A047",
        average: "#FFB300",
        negative: "#E53935",
        primary: "#1E88E5",
        secondary: "#00ACC1",
        neutral: "#8E24AA",
    },
};

export default variables;