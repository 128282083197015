import { UserState } from "actors/user.actor";
import ActionButton from "components/widgets/action-button";

import { gameActor } from "services";
import { GuiState } from "states/gui.state";
import { GameState } from "actors/game.actor";
import { DigimonState } from "actors/digimon.actor";

export default function ScoopButton(props: {
    gui: GuiState,
    game: GameState,
    user?: UserState,
    digimon?: DigimonState,
})
{

    // Actions:
    const scoop = () => {
        const action: string = props.user!.action === "scoop" ? "" : "scoop";
        gameActor.userActor?.setAction(action);
    }

    // Component:
    return props.digimon && props.digimon.dto.level > 0 ? (
        <ActionButton
            icon="scoop"
            name="Scoop"
            description="A scoop for the poop! Can also remove unwanted food."
            active={props.user?.action === "scoop"}
            onClick={scoop}
        />
    ) : (<></>);
}