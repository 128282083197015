import { createUseStyles } from "react-jss";

import { GameState } from "actors/game.actor";

const styles = createUseStyles<any, {
    game: GameState,
    dayBrightness: (game: GameState) => number,
    dayColor: (game: GameState, red: number, green: number, blue: number) => string,
    wild: boolean,
}>({
    world: {
        pointerEvents: "all",
        position: "absolute",
        left: "0vw",
        right: "0vw",
        top: "0vh",
        bottom: "0vh",
        transform: "scale(1)",
    },
    layer: {
        pointerEvents: "none",
        position: "absolute",
        width: "100%",
        height: "100%",
        backgroundRepeatY: "no-repeat",
        backgroundRepeatX: "repeat",
        backgroundSize: "auto 100%",
        backgroundPosition: "bottom",
    },

    night: {
        backgroundSize: "auto",
        backgroundRepeat: "repeat",
        backgroundImage: "url(assets/regions/grassland/night.png)",
    },
    sky: {
        left: "-100vw",
        top: "-100vh",
        width: "300vw",
        height: "300vh",
        background: (props) => `radial-gradient(
            circle,
            ${props.dayColor(props.game, 109, 188, 243)} 0%,
            ${props.dayColor(props.game, 0, 61, 150)} 50%
        )`,
        backgroundRepeat: "no-repeat",
        backgroundPositionX: (props) => `calc(${(Math.cos(Math.PI * 2 * props.game.dayProgress - 0.25) * 40)}vw)`,
        backgroundPositionY: (props) => `${40 + (-Math.sin(Math.PI * 2 * props.game.dayProgress - 0.25) * 50)}vh`,
    },
    sun: {
        width: "50vh",
        height: "50vh",
        left: (props) => `calc(${50 + (Math.cos(Math.PI * 2 * props.game.dayProgress - 0.25) * 40)}vw - 25vh)`,
        bottom: (props) => `calc(${40 + (Math.sin(Math.PI * 2 * props.game.dayProgress - 0.25) * 50)}vh - 25vh)`,
        backgroundImage: "url(assets/regions/grassland/sun_spring.png)",
    },

    scene: {
        filter: (props) => `
            brightness(${0.5 + (props.dayBrightness(props.game) * 0.5)})
            contrast(${1.5 - (props.dayBrightness(props.game) * 0.5)})
        `,
    },
    actors: {
        filter: (props) => `
            brightness(${0.75 + (props.dayBrightness(props.game) * 0.25)})
            contrast(${1.5 - (props.dayBrightness(props.game) * 0.5)})
        `,
    },

    mountains: {
        bottom: 0,
        height: "70vh",
        backgroundImage: (props) => `url(assets/regions/grassland/mountains${props.wild ? "_wild" : ""}.png)`,
    },
    clouds: {
        top: 0,
        height: "40vh",
        backgroundImage: "url(assets/regions/grassland/clouds_spring01.png)",
        backgroundRepeat: "repeat-x",
        backgroundPositionX: (props) => (props.game.ticks * 0.25) % (1280 * 100),
    },
    cloudsB: {
        backgroundImage: "url(assets/regions/grassland/clouds_spring02.png)",
        backgroundPositionX: (props) => (props.game.ticks * 0.5) % (1280 * 100),
    },
    hills: {
        bottom: 0,
        height: "50vh",
        backgroundImage: (props) => `url(assets/regions/grassland/hills${props.wild ? "_wild" : ""}.png)`,
    },
    grass: {
        bottom: 0,
        height: "25vh",
        zIndex: 200,
        backgroundImage: (props) => `url(assets/regions/grassland/grass${props.wild ? "_wild" : ""}.png)`,
    },
});


export default function Grassland(props: {
    game: GameState,
    actorComponents: JSX.Element[],
})
{
    // Day Colors:
    const dayBrightness = (game: GameState) => {
        let brightness: number = 1;
        switch (game.phase) {

            // Dawn:
            case 0:
                brightness = game.phaseProgress;
                break;

            // Day:
            case 1:
                brightness = 1;
                break;

            // Dusk:
            case 2:
                brightness = 1 - game.phaseProgress;
                break;

            // Night:
            default:
                brightness = 0;
        }
        return brightness;
    };

    const dayColor = (game: GameState, red: number, green: number, blue: number, forceAlpha: boolean = false) => {
        let alpha: number = forceAlpha ? 1 : dayBrightness(game);
        return `rgba(${red}, ${green}, ${blue}, ${alpha})`;
    };

    // Component:
    const classes = styles({
        game: props.game,
        dayBrightness,
        dayColor,
        wild: !props.game.homeZone,
    });
    return (
        <div className={`${classes.layer}`}>
            <div className={`${classes.layer} ${classes.night}`} />
            <div className={`${classes.layer} ${classes.sky}`} />
            <div className={`${classes.layer} ${classes.sun}`} />
            <div className={`${classes.layer} ${classes.scene}`}>
                <div className={`${classes.layer} ${classes.mountains}`} />
                <div className={`${classes.layer} ${classes.clouds}`} />
                <div className={`${classes.layer} ${classes.clouds} ${classes.cloudsB}`} />
                <div className={`${classes.layer} ${classes.hills}`} />
            </div>
            <div className={`${classes.layer} ${classes.actors}`}>
                {props.actorComponents}
            </div>
            <div className={`${classes.layer} ${classes.scene}`}>
                <div className={`${classes.layer} ${classes.grass}`} />
            </div>
        </div>
    );
}