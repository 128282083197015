import { createUseStyles } from "react-jss";

import { DigimonState } from "actors/digimon.actor";

import variables from "styles/variables";
import Bar from "components/widgets/bar";
import Icon from "components/widgets/icon";

const styles = createUseStyles<any, Record<string, any>>({
    container: {
        pointerEvents: "none",
        position: "absolute",
        zIndex: 1024,
        width: "100%",
        height: "100%",
        display: "flex",
        flexDirection: "row",
        alignItems: "start",

        color: variables.colors.background,
        textShadow: "-1px 0 black, 0 1px black, 1px 0 black, 0 -1px black",
    },
    hud: {
        position: "relative",
        width: "100%",
        margin: 16,
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "start",
        gap: 16,
    },

    info: {
        width: "auto",
        flexGrow: 1,
        display: "flex",
        flexDirection: "column",
        gap: 8,
        fontSize: "1.4em",
    },
    nametag: {
        flexGrow: 1,
        margin: "0 16px",
    },
    conditions: {
        flexGrow: 1,
        margin: "0 16px",
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        gap: 8,
    },

    bars: {
        flexGrow: 0,
        display: "flex",
        flexDirection: "row",
        flexWrap: "wrap",
        justifyContent: "space-between",
        gap: 8,
        fontSize: "1em",
    },
    bar: {
        width: "20vw",
    },

    "@media screen and (max-width: 1024px)": {
        bar: {
            width: "26vw",
        },
    },

    "@media screen and (max-width: 720px)": {
        hud: {
            flexWrap: "wrap",
        },
        info: {
            flexDirection: "row",
        },
        conditions: {
            width: "100%",
            alignItems: "start",
        },
        bar: {
            width: "40vw",
        },
    },

    "@media screen and (max-width: 420px)": {
        bar: {
            width: "60vw",
        },
    },
});

export default function HUD(props: {
    digimon: DigimonState,
})
{
    // Classes:
    const classes = styles();

    // Conditions:
    const conditions: JSX.Element[] = props.digimon.dto.conditions.map(condition => {
        let name: string | undefined = undefined;
        let description: string | undefined = undefined;
        switch (condition) {
            case "sleeping":
                name = "Sleeping";
                description = "Your Digimon is fast asleep, food consumption and growth speeds are greatly reduced.";
                break;
            case "diet_bad":
                name = "Poor Diet";
                description = "Your Digimon has a bad diet, check their poop to see what they're missing then feed them suitable food.";
                break;
            case "poop_bad":
                name = "Poop Problem";
                description = "There might be too much or too little poop around your Digimon's home, this is bad for your Digimon's Comfort. Scoop up excess poop or leave poop to build up depending on your Digimon's preferences.";
                break;
            case "upset":
                name = "Upset";
                description = "Your Digimon is upset! Check their mood to see what's wrong.";
                break;
            case "lonely":
                name = "Lonely";
                description = "Your Digimon missed you while you were gone! Cheer them up with their preferred food or by playing.";
                break;
            case "digitama":
                name = "Digitama";
                description = "Your Digimon is a Digitama (Digimon Egg) waiting to hatch...";
                break;
            case "hatching":
                name = "Hatching";
                description = "Your Digitama is ready to hatch!";
                break;
            case "evolution":
                name = "Digivolution";
                description = "Your Digimon has grown up! Select a new base Digivolution to unlock!";
                break;
        }
        return (
            <Icon key={condition} icon={condition} name={name} description={description} style={{
                width: 30,
                height: 30,
            }} />
        );
    });
    
    // Component:
    return (
        <div className={classes.container}>
            <div className={classes.hud}>
                <div className={classes.info}>
                    <span className={classes.nametag}>{props.digimon.dto.display}</span>
                    <div className={classes.conditions}>
                        {conditions}
                    </div>
                </div>
                <div className={classes.bars}>
                    <Bar 
                        name={"Health"}
                        current={props.digimon.dto.stats.health}
                        max={props.digimon.dto.stats.healthMax}
                        showPercentage={true}
                        color={variables.colors.positive + "AA"}
                        colorMed={variables.colors.average + "AA"}
                        colorLow={variables.colors.negative + "AA"}
                        className={classes.bar}
                    />
                    <Bar 
                        name={"Food"}
                        current={props.digimon.dto.stats.food ?? 0}
                        max={props.digimon.dto.stats.foodMax ?? 1}
                        showPercentage={true}
                        color={variables.colors.positive + "AA"}
                        colorMed={variables.colors.average + "AA"}
                        colorLow={variables.colors.negative + "AA"}
                        className={classes.bar}
                    />
                    <Bar 
                        name={"Comfort"}
                        current={props.digimon.dto.stats.comfort ?? 0}
                        max={props.digimon.dto.stats.comfortMax ?? 1}
                        showPercentage={true}
                        color={variables.colors.positive + "AA"}
                        colorMed={variables.colors.average + "AA"}
                        colorLow={variables.colors.negative + "AA"}
                        className={classes.bar}
                    />
                    <Bar 
                        name={"Dynamis"}
                        current={props.digimon.dto.stats.dynamis ?? 0}
                        max={props.digimon.dto.stats.dynamisMax ?? 1}
                        showPercentage={true}
                        color={variables.colors.primary + "AA"}
                        className={classes.bar}
                    />
                    <Bar 
                        name={"Growth"}
                        unit={"zqb"}
                        current={props.digimon.dto.stats.growth}
                        max={props.digimon.dto.stats.growthMax}
                        showPercentage={true}
                        showValue={true}
                        color={variables.colors.neutral + "AA"}
                        className={classes.bar}
                    />
                </div>
            </div>
        </div>
    );
}