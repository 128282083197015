import { Socket } from "socket.io-client"

import { AbstractService } from "services/abstract.service";
import { UserActionDto, UserDto } from "dtos/user.dto";

export class UserService extends AbstractService<UserDto>
{
    /**
     * Constructor
     * @param socket The connection socket.
     */
    public constructor(socket: Socket)
    {
        super(socket);

        // User:
        this.socket.on('user', (data: UserDto) => {
            // console.log(`[User Service] User ${data.username} updated.`);
            this.listeners.forEach(listener => listener('user', data));
        });
    }

    /**
     * Sends a user action message.
     * @param dto The message data to send.
     */
    public action(dto: UserActionDto): void
    {
        console.log(`[User Service] Sending ${dto.action} ${dto.value} action...`);
        this.socket.emit('user.action', dto);
    }
}