import { createUseStyles } from "react-jss";

import { DigimonDefDto } from "dtos/digimon.dto";

import { setGui } from "components/app";
import variables from "styles/variables";

const styles = createUseStyles<any, {
    name: string,
    type: string,
    size: string,
    hover: boolean,
}>({
    container: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: 'space-between',
        width: (props) => props.size === "small" ? 64 : 128,
        height: (props) => props.size === "small" ? 64 : 128,
        overflow: "hidden",
        margin: (props) => props.size === "small" ? 4 : 8,
        padding: (props) => props.size === "small" ? 0 : 16,
        borderRadius: 16,
        background: variables.colors.secondary + "AA",

        "&:hover": {
            background: (props) => props.hover ? variables.colors.primary + "AA" : variables.colors.secondary + "AA",
        },
        "& > h1": {
            display: (props) => props.size === "small" ? "none" : "inline",
            marginBottom: 0,
            textAlign: 'center',
            fontSize: "0.9em",
        },
    },
    selected: {
        background: variables.colors.positive + "AA",
        "&:hover": {
            background: variables.colors.positive + "AA !important",
        },
    },
    digimon: {
        flexGrow: 0,
        width: (props) => props.size === "small" ? 60 : 80,
        height: (props) => props.size === "small" ? 60 : 80,

        backgroundImage: (props) => `url(assets/digimon/${props.type}/${props.name}.png)`,
        backgroundSize: (props) => props.size === "small" ? 60 : 80,
        backgroundRepeat: "no-repeat",
        backgroundPosition: "top",
        imageRendering: "pixelated",
    },
});

export default function DigimonButton(props: {
    digimonDef: DigimonDefDto,
    selected?: boolean,
    size?: string,
    tooltip?: boolean
    onClick?: () => void,
})
{
    // Mouse:
    const mouseEnter = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        if (!props.tooltip) {
            return;
        }
        setGui({
            hover: {
                title: props.digimonDef.name,
                description: `Type: ${props.digimonDef.type} (Level ${props.digimonDef.level})`,
            },
        });
    };
    
    const mouseLeave = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        if (!props.tooltip) {
            return;
        }
        setGui({
            hover: null,
        });
    };

    const click = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        if (props.onClick) {
            props.onClick();
        }
        if (!props.tooltip) {
            return;
        }
        setGui({
            hover: null,
        });
    };

    // Component:
    const classes = styles({
        ...props.digimonDef,
        size: props.size ?? "large",
        hover: !!props.onClick,
    });
    return (
        <div
            className={`${classes.container} ${props.selected ? classes.selected : ""}`}
            onClick={click}
            onMouseEnter={mouseEnter}
            onMouseLeave={mouseLeave}
        >
            <h1>{props.digimonDef.display}</h1>
            <div className={classes.digimon} />
        </div>
    );
}