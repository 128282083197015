import { gameActor } from "services";
import { GameState } from "actors/game.actor";
import { UserState } from "actors/user.actor";
import { DigimonState } from "actors/digimon.actor";

import { setGui } from "components/app";
import { GuiState } from "states/gui.state";
import ActionButton from "components/widgets/action-button";

export default function MenuButton(props: {
    gui: GuiState,
    game: GameState,
    user?: UserState,
    digimon?: DigimonState,
    active: boolean,
})
{
    // Actions:
    const menu = () => {
        gameActor.userActor?.setAction("");
        setGui({
            submenu: props.gui.submenu === "menu" ? "" : "menu",
        });
    };

    // Component:
    return (
        <ActionButton
            icon="menu"
            name="Menu"
            description="Open or close the system menu."
            active={props.active}
            onClick={() => menu()}
        />
    );
}