import { SocketsService } from "services/sockets.service";
import { LoginService } from "services/login.service";
import { UserService } from "services/user.service";
import { DigimonService } from "services/digimon.service";
import { ItemService } from "services/item.service";
import { RegionService } from "services/region.service";
import { GameActor } from "actors/game.actor";
import { ZoneService } from "services/zone.service";

console.info("[Services] Initialising services and main game actor...");

export const socketsService = new SocketsService();
export const loginService = new LoginService(socketsService.socket);
export const userService = new UserService(socketsService.socket);
export const digimonService = new DigimonService(socketsService.socket);
export const itemService = new ItemService(socketsService.socket);
export const regionService = new RegionService(socketsService.socket);
export const zoneService = new ZoneService(socketsService.socket);

export const gameActor = new GameActor();