import { useEffect, useState } from "react";
import { createUseStyles } from "react-jss";

import variables from "styles/variables";

import { UserState } from "actors/user.actor";
import { GameState } from "actors/game.actor";
import { DigimonState } from "actors/digimon.actor";

import { GuiState } from "states/gui.state";
import StatsButton from "components/gui/actions/stats.button";
import MenuButton from "components/gui/actions/menu.button";
import FoodButton from "components/gui/actions/food.button";
import FoodActions from "components/gui/actions/food.actions";
import MenuActions from "components/gui/actions/menu.actions";
import ScoopButton from "components/gui/actions/scoop.button";
import SleepButton from "components/gui/actions/sleep.button";
import ToysButton from "components/gui/actions/toys.button";
import ToyActions from "components/gui/actions/toy.actions";
import ExploreButton from "components/gui/actions/explore.button";
import ExploreActions from "components/gui/actions/explore.actions";

const styles = createUseStyles<any, {
    action: string,
    itemName?: string,
    itemType?: string,
    mouseX: number,
    mouseY: number,
}>({
    container: {
        pointerEvents: "none",
        position: "absolute",
        zIndex: 1024,
        width: "100%",
        height: "100%",
        bottom: 0,
        marginBottom: 16,
        display: "flex",
        flexDirection: "row",
        alignItems: "end",
        justifyContent: 'space-between',

        color: variables.colors.background,
        textShadow: "-1px 0 black, 0 1px black, 1px 0 black, 0 -1px black",
    },
    buttons: {
        margin: "0 16px",
        flexGrow: 0,
        display: "flex",
        flexDirection: "row",
        flexWrap: "wrap",
        alignItems: "start",
        justifyContent: 'left',
    },
    buttonsSecondary: {
        alignItems: "right",
        justifyContent: 'right',
    },
    mouse: {
        display: (props) => props.action ? "block" : "none",
        position: "fixed",
        left: (props) => props.mouseX + 16,
        top: (props) => props.mouseY - 16 - 64,
        width: 64,
        height: 64,
        backgroundImage: (props) => `url(assets/icons/${props.action}.svg)`,
        backgroundSize: "contain",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
        filter: "brightness(1) contrast(1.5)",
    },
    mouseItem: {
        display: (props) => props.itemName && props.itemType ? "block" : "none",
        position: "fixed",
        left: (props) => props.mouseX + 24,
        top: (props) => props.mouseY - 24 - 48,
        width: 48,
        height: 48,
        backgroundImage: (props) => `url(assets/items/${props.itemType}/${props.itemName}.png)`,
        backgroundSize: "contain",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
        imageRendering: "pixelated",
        filter: "brightness(1) contrast(1.5)",
    },

    "@media screen and (max-width: 1024px)": {
        container: {
            height: "50%",
        },
        buttons: {
            height: "100%",
            margin: "0 8px",
            flexDirection: "column",
            justifyContent: 'end',
        },
        buttonsSecondary: {
            width: "100%",
            grow: 1,
            alignContent: "flex-end",
        },
    },
});

export default function UserActions(props: {
    gui: GuiState,
    game: GameState,
    user?: UserState,
    digimon?: DigimonState,
})
{
    // States:
    const [mouseState, setMouseState] = useState({
        mouseX: 0,
        mouseY: 0,
    });

    // Mouse:
    useEffect(() => {
        const handleMouseMove = (event: MouseEvent) => {
            setMouseState({
                mouseX: event.clientX,
                mouseY: event.clientY,
            });
        };
        window.addEventListener("mousemove", handleMouseMove);
        return () => {
            window.removeEventListener("mousemove", handleMouseMove);
        };
    }, []);

    // Submenu:
    let submenuName: string | undefined = props.gui.submenu;
    if (props.user?.action === "food") {
        submenuName = "food";
    } else if (submenuName === "food") {
        submenuName = "";
    }
    if (props.user?.action === "toys") {
        submenuName = "toys";
    } else if (submenuName === "toys") {
        submenuName = "";
    }

    // Submenu Buttons:
    let submenuActions: JSX.Element | undefined;
    switch (submenuName) {
        case "menu":
            submenuActions = (
                <MenuActions
                    gui={props.gui}
                    game={props.game}
                    user={props.user}
                    digimon={props.digimon}
                />
            );
            break;
        case "food":
            submenuActions = (
                <FoodActions
                    gui={props.gui}
                    game={props.game}
                    user={props.user}
                    digimon={props.digimon}
                />
            );
            break;
        case "toys":
            submenuActions = (
                <ToyActions
                    gui={props.gui}
                    game={props.game}
                    user={props.user}
                    digimon={props.digimon}
                />
            );
            break;
        case "explore":
            submenuActions = (
                <ExploreActions
                    gui={props.gui}
                    game={props.game}
                    user={props.user}
                    digimon={props.digimon}
                />
            );
            break;
    }

    // Component:
    const classes = styles({
        ...props,
        action: props.user?.action ?? "",
        itemName: props.user?.item?.name,
        itemType: props.user?.item?.type,
        ...mouseState,
    });
    return (
        <div className={`${classes.container}`}>
            <div className={`${classes.buttons}`}>
                <MenuButton
                    gui={props.gui}
                    game={props.game}
                    user={props.user}
                    digimon={props.digimon}
                    active={submenuName === "menu"}
                />
                <StatsButton
                    gui={props.gui}
                    game={props.game}
                    user={props.user}
                    digimon={props.digimon}
                />
                <SleepButton
                    gui={props.gui}
                    game={props.game}
                    user={props.user}
                    digimon={props.digimon}
                />
                <ScoopButton
                    gui={props.gui}
                    game={props.game}
                    user={props.user}
                    digimon={props.digimon}
                />
                <FoodButton
                    gui={props.gui}
                    game={props.game}
                    user={props.user}
                    digimon={props.digimon}
                    active={submenuName === "food"}
                />
                <ToysButton
                    gui={props.gui}
                    game={props.game}
                    user={props.user}
                    digimon={props.digimon}
                    active={submenuName === "toys"}
                />
                <ExploreButton
                    gui={props.gui}
                    game={props.game}
                    user={props.user}
                    digimon={props.digimon}
                    active={submenuName === "explore"}
                />
            </div>
            <div className={`${classes.buttons} ${classes.buttonsSecondary}`}>
                {submenuActions}
            </div>
            <div className={`${classes.mouse}`} />
            <div className={`${classes.mouseItem}`} />
        </div>
    );
}