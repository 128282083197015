import { Socket } from "socket.io-client"

import { AbstractService } from "services/abstract.service";
import { ItemDto, ItemRequestDto, ItemPartialDto, ItemDefIndexRequestParams, ItemDefIndexDto, ItemDefDto } from "dtos/item.dto";
import { ErrorDto } from "dtos/error.dto";
import { DigimonDto } from "dtos/digimon.dto";

export class ItemService extends AbstractService<ItemDto>
{
    public foodItems: ItemDefDto[] = [];
    public toyItems: ItemDefDto[] = [];

    /**
     * Constructor
     * @param socket The connection socket.
     */
    public constructor(socket: Socket)
    {
        super(socket);

        // Item:
        this.socket.on('item', (data: ItemDto) => {
            this.listeners.forEach(listener => listener('item', data));
        });

        this.socket.on('item.destroy', (data: ItemDto) => {
            this.listeners.forEach(listener => listener('item.destroy', data));
        });

        this.socket.on('item.create.error', (data: ErrorDto) => {
            console.error(data);
        });
        this.socket.on('item.destroy.error', (data: ErrorDto) => {
            console.error(data);
        });

        // Directories:
        this.indexDefinitions({
            type: "food",
        }).then(indexDto => {
            this.foodItems = indexDto.items;
        });
        this.indexDefinitions({
            type: "toy",
        }).then(indexDto => {
            this.toyItems = indexDto.items;
        });
    }

    /**
     * Sends an index definitions request.
     * @param params Optional params to filter the index by, etc.
     */
    public async indexDefinitions(params?: ItemDefIndexRequestParams): Promise<ItemDefIndexDto>
    {
        console.log("[Item Service] Requesting definitions index...");
        return await this.api.get("item-defs", {
            params,
        }).then(response => {
            console.log(`[Item Service] Received ${response.data.items?.length} definition(s).`);
            return response.data
        });
    }

    /**
     * Sends an item create message.
     * @param data The message data to send.
     */
    public create(data: ItemRequestDto): void
    {
        console.log("[Item Service] Creating item...");
        this.socket.emit('item.create', data);
    }

    /**
     * Sends an item destroy message.
     * @param data The message data to send.
     */
    public destroy(data: ItemPartialDto): void
    {
        console.log("[Item Service] Destroying item...");
        this.socket.emit('item.destroy', data);
    }

    /**
     * Generates a color for gui usage based on the provided item type.
     * @param type The item type to get a color for.
     * @return A color for gui usage based on the provided item type.
     */
    public getColor(type: string): string
    {
        switch(type) {
            case "food":
                return "#FB8C00";
            case "poop":
                return "#6D4C41";
            case "toy":
                return "#43A047";
            default:
                return "#8E24AA";
        }
    }

    /**
     * Gets a description for the provided item name and type.
     * @param name The name of the item to get a desciption for.
     * @param type The type of the item to get a description for.
     * @param digimonDto Optional digimon dto to tailor descriptions for (food preferences, etc).
     * @returns 
     */
    public getDescription(name: string, type: string, digimonDto?: DigimonDto): string
    {
        let description: string = "This is an unknown item.";
        switch (type) {
            case "food":
                switch (name) {
                    case "bread":
                        description = "A soft bread roll made with fine grains.";
                        break;
                    case "choc":
                        description = "A fancy sweet chocolate bar.";
                        break;
                    case "fish":
                        description = "A smoked tender fillet of fish.";
                        break;
                    case "fruit":
                        description = "A refreshing ripe fruit.";
                        break;
                    case "meat":
                        description = "A juicy chunk of cooked meat.";
                        break;
                    case "nuts":
                        description = "A fresh assortment of nuts.";
                        break;
                    case "shroom":
                        description = "A large hearty mushroom.";
                        break;
                    case "veg":
                        description = "A bundle of lush green vegetables.";
                        break;
                }
                if (digimonDto?.foodPreferences?.likes.includes(name)) {
                    description += " Your Digimon prefers this kind of food!";
                } else if (digimonDto?.foodPreferences?.hates.includes(name)) {
                    description += " Your Digimon hates this kind of food!";
                }
            break;

            case "poop":
                switch (name) {
                    case "poop":
                        description = "A healthy pile of poop.";
                        break;
                    case "fudge":
                        description = "A wet, smooth whip of poop, a sign of low protein.";
                        break;
                    case "truffles":
                        description = "Hard round lumps of poop, a sign of low calcium.";
                        break;
                    case "raisins":
                        description = "Separate hard lumps of poop, a sign of low fat.";
                        break;
                    case "pudding":
                        description = "Mushy poop with ragged edges, a sign of low carbs.";
                        break;
                    case "brownies":
                        description = "Soft blobs of poop with clear cut edges, a sign of low fiber.";
                        break;
                    case "rockyroad":
                        description = "A dense lumpy poop, a sign of low iron.";
                        break;
                    case "custard":
                        description = "A goopy pool of poop, a sign of low vits.";
                        break;
                }
            break;

            case "toy":
                switch (name) {
                    case "ball":
                        description = "A ball for playing fetch.";
                        break;
                }
            break;
        }

        return description;
    }

    /**
     * Parses the metadata of the provided item dto.
     * @param dto The item data transfer object.
     * @returns An array of objects with a key, display name and stat value.
     */
    public getMetadata(dto: ItemDto): {
        key: string,
        name: string,
        value: any,
    }[]
    {
        switch (dto.type) {
            case "poop":
                return Object.keys(dto.metadata).map(key => {
                    let name: string = "Unknown";
                    switch (key) {
                        case "protein":
                            name = "Protein";
                            break;
                        case "calcium":
                            name = "Calcium";
                            break;
                        case "fat":
                            name = "Fat";
                            break;
                        case "carbs":
                            name = "Carbs";
                            break;
                        case "fiber":
                            name = "Fiber";
                            break;
                        case "iron":
                            name = "Iron";
                            break;
                        case "vits":
                            name = "Vits";
                            break;
                    }
                    return {
                        key,
                        name,
                        value: dto.metadata[key],
                    }
                });
        }

        return [];
    }

    /**
     * Parses the values that the provided item def dto is high in (such as diet stats that foods are high in).
     * @param dto The item definition data transfer object.
     * @returns An array of objects with a key and display name.
     */
    public getHighs(dto: ItemDefDto): {
        key: string,
        name: string,
    }[]
    {
        switch (dto.type) {
            case "food":
                return dto.high?.map(key => {
                    let name: string = "Unknown";
                    switch (key) {
                        case "protein":
                            name = "High in Protein";
                            break;
                        case "calcium":
                            name = "High in Calcium";
                            break;
                        case "fat":
                            name = "High in Fat";
                            break;
                        case "carbs":
                            name = "High in Carbs";
                            break;
                        case "fiber":
                            name = "High in Fiber";
                            break;
                        case "iron":
                            name = "High in Iron";
                            break;
                        case "vits":
                            name = "High in Vits";
                            break;
                    }
                    return {
                        key,
                        name,
                    }
                }) ?? [];
        }

        return [];
    }
}