import { useEffect, useState } from "react";
import { createUseStyles } from "react-jss";

import { socketsService } from "services";
import { GuiState } from "states/gui.state";
import { GameState } from 'actors/game.actor';

import widgets from "styles/widgets";
import ItemTooltip from "components/widgets/item-tooltip";
import HoverTooltip from "components/widgets/hover-tooltip";
import Login from "components/gui/login";
import Digitama from "components/gui/digitama";
import Hatch from "components/gui/hatch";
import UserActions from "components/gui/user-actions";
import Evolve from "components/gui/evolve";

const styles = createUseStyles<any, Record<string, any>>({
    gui: {
        pointerEvents: "none",
        position: 'absolute',
        zIndex: 1024,
        width: '100%',
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
});

export default function GUI(props: {
    game: GameState,
    gui: GuiState,
})
{
    // Sockets State:
    const [socketsState, setSocketsState] = useState({
        status: socketsService.status,
    });
    useEffect(() =>  socketsService.addListener((status: string) => {
        setSocketsState({
            status: socketsService.status,
        });
    }));

    const classes = styles();
    const widgetClasses = widgets();
    let component: JSX.Element | undefined;

    // Connecting Popup:
    if (socketsState.status === 'connecting') {
        component = (
            <div className={widgetClasses.popup}>
                <h1>Connecting</h1>
                <p>Connecting to Digimon Gemini Server...</p>
            </div>
        );
    }

    // Connection Error Popup:
    else if (socketsState.status === 'error') {
        component = (
            <div className={widgetClasses.popup}>
                <h1>Connection Lost</h1>
                <p>Attempting to reconnect to Digimon Gemini Server...</p>
            </div>
        );
    }

    // Disconnected Popup:
    else if (socketsState.status === 'disconnected') {
        component = (
            <div className={widgetClasses.popup}>
                <h1>Disconnected</h1>
                <p>Reconnecting to server...</p>
            </div>
        );
    }

    // General Popup:
    else if (props.gui.popup) {
        component = props.gui.popup;
    }

    // Login Popup:
    else if (!props.game.mainUser) {
        component = (<Login />);
    }

    // Digitama Selection:
    else if (!props.game.mainDigimon) {
        component = (<Digitama />);
    }

    // Hatch:
    else if (props.game.mainDigimon.dto.conditions.includes("hatching")) {
        component = (<Hatch digimon={props.game.mainDigimon} />);
    }

    // New Evolution:
    else if (props.game.mainDigimon.dto.conditions.includes("evolution")) {
        component = (<Evolve digimon={props.game.mainDigimon} />);
    }

    return (
        <div className={classes.gui}>
            {component}
            <UserActions gui={props.gui} game={props.game} user={props.game.mainUser} digimon={props.game.mainDigimon} />
            {props.gui.hoverItem && (
                <ItemTooltip item={props.gui.hoverItem} />
            )}
            {props.gui.hover && (
                <HoverTooltip hover={props.gui.hover} />
            )}
            {}
        </div>
    );
}