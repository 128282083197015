import { Socket } from "socket.io-client"

import { AbstractService } from "services/abstract.service";
import { RegionDto, RegionDefIndexDto, RegionDefDto } from "dtos/region.dto";

export class RegionService extends AbstractService<RegionDto>
{
    public regions: RegionDefDto[] = [];

    /**
     * Constructor
     * @param socket The connection socket.
     */
    public constructor(socket: Socket)
    {
        super(socket);

        // Region:
        this.socket.on('region', (data: RegionDto) => {
            // console.log(`[Region Service] Region ${data.name} updated.`);
            this.listeners.forEach(listener => listener('region', data));
        });

        // Directories:
        this.indexDefinitions().then(indexDto => {
            this.regions = indexDto.items;
        });
    }

    /**
     * Sends an index definitions request.
     */
    public async indexDefinitions(): Promise<RegionDefIndexDto>
    {
        console.log("[Region Service] Requesting definitions index...");
        return await this.api.get("region-defs", {}).then(response => {
            console.log(`[Region Service] Received ${response.data.items?.length} definition(s).`);
            return response.data
        });
    }

    /**
     * Sends a sigle definition request.
     * @param name The name of the definition to get.
     */
    public async getDefinition(name: string): Promise<RegionDefDto>
    {
        console.log(`[Region Service] Requesting ${name} definition...`);
        return await this.api.get("region-defs/" + name,).then(response => {
            console.log(`[Region Service] Received ${response.data.name} definition.`);
            return response.data
        });
    }
}